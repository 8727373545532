import Page from "@fluidily/Views/Page";
import SnapshotView from "@fluidily/Views/Snapshot";
import { useWebsocket } from "@fluidily/hooks";
import { ChannelId, Snapshot } from "@fluidily/stores";
import React from "react";
import { useParams } from "react-router-dom";
import Gateway from "./Gateway";

const ServiceHome = () => {
  const channel = ChannelId.snapshot();
  const [snapshot, setSnapshot] = React.useState<Snapshot | null>(null);
  const { serviceName } = useParams() as { serviceName: string };
  useWebsocket((snapshot?: Snapshot) => {
    if (snapshot && snapshot.service && snapshot.service.name === serviceName) {
      setSnapshot(snapshot);
    }
  }, channel);
  if (!snapshot) {
    return null;
  }
  const tabs = [{ text: "Services", to: `/admin/services` }];
  return (
    <Page title={snapshot.service.name} tabs={tabs}>
      {snapshot.json.market_data ? (
        <Gateway snapshot={snapshot} />
      ) : (
        <SnapshotView snapshot={snapshot} />
      )}
    </Page>
  );
};

export default ServiceHome;
