export const asNumber = (value: any): number => {
  if (typeof value === "number") {
    return value;
  }

  if (typeof value === "string") {
    return parseFloat(value);
  }

  return 0;
};

export const asNumberOr = (value: any, defaultValue: any): any => {
  if (typeof value === "number") {
    return value;
  }

  if (typeof value === "string") {
    return parseFloat(value);
  }

  return defaultValue;
};
