import rowDate from "@fluidily/Components/dateFormatter";
import Page from "@fluidily/Views/Page";
import { useWebsocket } from "@fluidily/hooks";
import { ChannelId, Snapshot } from "@fluidily/stores";
import { formatDateTimeSeconds, timestampToDate } from "@fluidily/utils/dates";
import { StaticData } from "@metablock/core";
import { ApiDataGrid, Link } from "@metablock/react";
import React from "react";

const Services = () => {
  const channel = ChannelId.snapshot();
  const [data, setSnapshot] = React.useState<any[]>([]);
  const api = new StaticData(data);
  useWebsocket((snapshot?: Snapshot) => {
    if (snapshot && snapshot.service) {
      const d = {
        ...snapshot.service,
        timestamp: snapshot.timestamp.total_nanos,
      };
      const index = data.findIndex((s) => d.uid === s.uid);
      if (index > -1) {
        data[index] = d;
      } else {
        data.push(d);
        data.sort((a, b) => (b.name > a.name ? 1 : -1));
      }
      const now = new Date().getTime();
      setSnapshot(
        data.filter(
          (s) => now - timestampToDate(s.timestamp).getTime() < 10000,
        ),
      );
    }
  }, channel);

  const columns = React.useMemo(
    () => [
      {
        key: "uid",
        name: "UID",
        minWidth: 200,
        resizable: true,
      },
      {
        key: "name",
        name: "Name",
        minWidth: 80,
        resizable: true,
        renderCell: ({ row }: any) => (
          <Link to={`/admin/services/${row.name}`}>{row.name}</Link>
        ),
      },
      {
        key: "timestamp",
        name: "Timestamp",
        minWidth: 120,
        resizable: true,
        renderCell: rowDate("timestamp", formatDateTimeSeconds),
      },
      {
        key: "region",
        name: "Region",
      },
    ],
    [],
  );

  return (
    <Page title="services">
      <ApiDataGrid api={api} columns={columns} resize={true} />
    </Page>
  );
};

export default Services;
