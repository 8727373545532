import { DataApi } from "@metablock/core";
import { Entity, Fluidily } from "./client";

class EntitiesStore {
  fluidily: Fluidily;

  constructor(cli: Fluidily) {
    this.fluidily = cli;
  }

  loader(query?: Record<string, any>): DataApi<Entity> {
    return this.fluidily.loader(`${this.fluidily.apiUrl}/entities`, query);
  }

  async create(data: Record<string, any>) {
    return await this.fluidily.entities.create(data);
  }
}

export { EntitiesStore };
