import { HomeIcon } from "@fluidily/Components/Icons";
import MenuEntry from "@fluidily/Components/MenuEntry";
import { getTab } from "@fluidily/utils/breadcrumbs";
import { Buttons } from "@metablock/react";
import {
  Container,
  Hidden,
  AppBar as MuiAppBar,
  Stack,
  Toolbar,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import OrgMenu from "./OrgMenu";
import Search from "./Search";
import UserMenu from "./UserMenu";

const leftLinks = [
  { text: "", icon: <HomeIcon fontSize="inherit" />, to: `/` },
  { text: "Securities", to: `/data/securities` },
  { text: "Exchanges", to: `/data/exchanges` },
  { text: "Betting", to: `/data/betting-events` },
];

const Items = (props: any) => {
  const location = useLocation();
  const { pathname } = location;
  const { direction = "vertical" } = props;
  const path = getTab(leftLinks, pathname);
  return (
    <Stack direction={direction} spacing={0.5}>
      <Buttons
        tabs={leftLinks}
        color="primary"
        size="small"
        path={path}
        spacing={0.5}
      />
      <OrgMenu size="small" />
    </Stack>
  );
};

const AppBar = () => {
  return (
    <MuiAppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Hidden mdDown>
            <Items direction="row" />
          </Hidden>
          <Hidden mdUp>
            <MenuEntry Items={Items} />
          </Hidden>
          <Search size="small" />
          <UserMenu size="small" />
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
};

export default AppBar;
