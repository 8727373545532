import { TradingState } from "./market";
import { SecurityKey } from "./security";
import { Service } from "./service";

export interface LeagueSeason {
  id: number;
  name: string;
  full_name: string;
  country: string;
  season: number;
  current: boolean;
  tracked: boolean;
  event_type: string;
  league_type: string;
  sex: string;
  surface: string;
  start?: string;
  end?: string;
  extra: Record<string, any>;
}

export interface Player {
  name: string;
  full_name: string;
  country: string;
  dob: string;
  id: number;
  sex: string;
  event_type: string;
  rank: number;
  extra: Record<string, any>;
}

export interface BettingContract {
  id: number;
  security_key: SecurityKey;
  exchange_id: string;
  state: string;
  contract_type: string;
  volume: string;
  last_trade_price: string;
}

export interface BettingMarket {
  id: number;
  market_type: string;
  market_type_param: string;
  exchange_id: string;
  state: string;
  start: Date;
  betting_contracts: BettingContract[];
}

export interface BettingEvent {
  id: number;
  security_key: SecurityKey;
  full_name: string;
  exchange_id: string;
  state: string;
  event_type: string;
  start: Date;
  betting_markets: BettingMarket[];
  league_season: LeagueSeason;
}

export interface TradableBettingEvent {
  id: number;
  betting_event: BettingEvent;
  strategy: string;
  trading_state: TradingState;
  service: Service;
}

export const bettingMarketVolume = (market: BettingMarket): number => {
  return market.betting_contracts.reduce(
    (acc, contract) => acc + +contract.volume,
    0,
  );
};

export const bettingEventVolume = (event: BettingEvent): number => {
  return event.betting_markets.reduce(
    (acc, market) => acc + bettingMarketVolume(market),
    0,
  );
};

export const leagueName = (league_season: LeagueSeason): string =>
  `${league_season.full_name} - ${league_season.season}`;
