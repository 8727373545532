import { ColorMoney } from "@fluidily/Components/Money";
import Panel from "@fluidily/Components/Panel";
import PlotReact from "@fluidily/Viz/PlotReact";
import { TradableBettingEvent } from "@fluidily/stores";
import { round } from "@fluidily/utils/money";
import { Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { cell, text } from "@observablehq/plot";
import React from "react";
import { panelProps } from "./Utils";

const roundModel = round(5);

const FootballView = ({
  tradableEvent,
  snapshot,
}: {
  tradableEvent: TradableBettingEvent;
  snapshot: any;
}) => {
  if (!snapshot) return null;
  const be = tradableEvent.betting_event;
  const state = snapshot.json?.state || {};
  const signal = state.football_match_signal || {};
  const match = signal.match || {};
  const grid = { sm: 12, md: 6 };
  const panel = panelProps();
  const bits = be.full_name.split(" v ").map((s) => s.trim());
  const home = bits[0] || "home";
  const away = bits[1] || "away";
  const homeScore = match.score?.home || 0;
  const awayScore = match.score?.away || 0;
  const exposureOptions = getHistOptions(
    signal.exposure,
    home,
    away,
    homeScore,
    awayScore,
  );
  const exposureEvOptions = getHistOptions(
    signal.exposure_ev,
    home,
    away,
    homeScore,
    awayScore,
  );
  const probsOptions = getHistOptions(
    signal.probabilities,
    home,
    away,
    homeScore,
    awayScore,
    {
      color: {
        scheme: "blues",
        label: "probability",
      },
      textFormat: (d: any) => (100 * d.value).toFixed(2) + "%",
    },
  );

  return (
    <Grid container spacing={1}>
      <Grid item {...grid}>
        <Panel
          title={`${be.full_name}   ${homeScore} - ${awayScore}`}
          {...panel}
        >
          <Ev be={be} signal={signal} />
        </Panel>
      </Grid>
      <Grid item {...grid}>
        <Panel {...panel}>
          <Model signal={signal} home={home} away={away} />
        </Panel>
      </Grid>
      <Grid item {...grid}>
        <Typography variant="h6" align="center">
          Goal Exposure
        </Typography>
        <PlotReact options={exposureOptions} />
      </Grid>
      <Grid item {...grid}>
        <Typography variant="h6" align="center">
          Goal Exposure EV
        </Typography>
        <PlotReact options={exposureEvOptions} />
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="h6" align="center">
          Correct score probabilities
        </Typography>
        <PlotReact options={probsOptions} />
      </Grid>
    </Grid>
  );
};

const Ev = ({ be, signal }: { be: any; signal: any }) => {
  const ev = +(signal.ev || 0);
  const timeDecay = +(signal.time_decay || 0);
  const minutes = Math.round(+signal.match?.event_time);
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>time</TableCell>
          <TableCell>{minutes.toFixed(0)} minutes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ev</TableCell>
          <TableCell>
            <ColorMoney amount={+ev.toFixed(2)} currency="gbp" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>time decay</TableCell>
          <TableCell>
            <ColorMoney amount={+timeDecay.toFixed(2)} currency="gbp" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const Model = ({
  signal,
  home,
  away,
}: {
  signal: any;
  home: string;
  away: string;
}) => {
  const homeDelta = +(signal.home_delta || 0);
  const awayDelta = +(signal.away_delta || 0);
  const homeModel = signal?.match?.home;
  const awayModel = signal?.match?.away;
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Team</TableCell>
          <TableCell>Delta</TableCell>
          <TableCell>Intensity</TableCell>
          <TableCell>Alpha</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{home}</TableCell>
          <TableCell>
            <ColorMoney amount={+homeDelta.toFixed(2)} currency="gbp" />
          </TableCell>
          <TableCell>{roundModel(homeModel?.intensity)}</TableCell>
          <TableCell>{roundModel(homeModel?.alpha)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{away}</TableCell>
          <TableCell>
            <ColorMoney amount={+awayDelta.toFixed(2)} currency="gbp" />
          </TableCell>
          <TableCell>{roundModel(awayModel?.intensity)}</TableCell>
          <TableCell>{roundModel(awayModel?.alpha)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const getHistOptions = (
  rawData: any,
  home: string,
  away: string,
  homeScore: number,
  awayScore: number,
  options?: any,
) => {
  const { data, minValue, maxValue } = toGrid(rawData || []);
  const defaultOptions = {
    color: {
      scheme: "rdylgn",
      pivot: 0,
      label: "exposure",
    },
    textFormat: (d: any, extra: any, foo: any) => {
      return d.value.toFixed(2);
    },
  };
  //@ts-ignore
  const { color, textFormat } = { ...defaultOptions, ...options };
  const p = Math.max(
    color.pivot === 0 ? Math.max(-minValue, maxValue) : maxValue,
    0.001,
  );

  return {
    padding: 0,
    y: { label: home },
    x: { label: away, axis: "top" },
    color,
    marks: [
      cell(data, {
        y: "home",
        x: "away",
        fill: (d: any) =>
          d.home >= homeScore && d.away >= awayScore ? d.value : 0,
      }),
      text(data, {
        y: "home",
        x: "away",
        text: textFormat,
        fill: (d: any) => {
          const value =
            d.home >= homeScore && d.away >= awayScore ? d.value : 0;
          return Math.abs(value / p) > 0.6 ? "white" : "black";
        },
      }),
    ],
  };
};

const toGrid = (d: number[][]) => {
  const data = [];
  let minValue = 0;
  let maxValue = 0;
  for (let home = 0; home < d.length - 2; home++) {
    const row = d[home];
    for (let away = 0; away < row.length - 2; away++) {
      const value = row[away];
      minValue = Math.min(minValue, value);
      maxValue = Math.max(maxValue, value);
      data.push({ home, away, value });
    }
  }
  return { data, minValue, maxValue };
};

export default FootballView;
