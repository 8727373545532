import { DataApi } from "@metablock/core";
import { BettingEvent, TradableBettingEvent } from "../protos";
import HttpComponent from "./httpComponent";

class BettingEvents extends HttpComponent {
  loader(query?: any): DataApi<BettingEvent> {
    return this.cli.loader(`${this.cli.apiUrl}/betting-events`, query);
  }

  tradable_loader(query?: any): DataApi<TradableBettingEvent> {
    return this.cli.loader(`${this.cli.apiUrl}/tradable-betting-events`, query);
  }

  async get_event(eventId: number | string): Promise<BettingEvent> {
    const response = await this.cli.get(
      `${this.cli.apiUrl}/betting-events/${eventId}`,
    );
    return response.data as BettingEvent;
  }

  async get_tradable(eventId: number | string): Promise<TradableBettingEvent> {
    const response = await this.cli.get(
      `${this.cli.apiUrl}/tradable-betting-events/${eventId}`,
    );
    return response.data as TradableBettingEvent;
  }

  async update_tradable(
    eventId: number | string,
    body: any,
  ): Promise<TradableBettingEvent> {
    const response = await this.cli.patch(
      `${this.cli.apiUrl}/tradable-betting-events/${eventId}`,
      { body },
    );
    return response.data as TradableBettingEvent;
  }
}

export default BettingEvents;
