import { DataApi } from "@metablock/core";
import HttpComponent from "./httpComponent";
import { Dashboard } from "./interfaces";

class Dashboards extends HttpComponent {
  loader(query?: any): DataApi<Dashboard> {
    return this.cli.loader(`${this.cli.apiUrl}/dashboards`, query);
  }

  async create(body: any): Promise<Dashboard> {
    const url = `${this.cli.apiUrl}/dashboards`;
    const response = await this.cli.post(url, { body });
    return response.data as Dashboard;
  }

  async get(name_or_id: string): Promise<Dashboard> {
    const url = `${this.cli.apiUrl}/dashboards/${name_or_id}`;
    const response = await this.cli.get(url);
    return response.data as Dashboard;
  }

  async update(id: number, body: any): Promise<Dashboard> {
    const url = `${this.cli.apiUrl}/dashboards/${id}`;
    const response = await this.cli.patch(url, { body });
    return response.data as Dashboard;
  }

  async delete(id: number): Promise<void> {
    const url = `${this.cli.apiUrl}/dashboards/${id}`;
    await this.cli.delete(url);
  }
}

export default Dashboards;
