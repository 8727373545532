import { ColorMoney } from "@fluidily/Components/Money";
import Panel from "@fluidily/Components/Panel";
import { TradableBettingEvent } from "@fluidily/stores";
import { asNumber, asNumberOr } from "@fluidily/utils/numbers";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "d3-format";
import React from "react";
import { panelProps } from "./Utils";

const TennisView = ({
  tradableEvent,
  snapshot,
}: {
  tradableEvent: TradableBettingEvent;
  snapshot: any;
}) => {
  if (!snapshot) return null;
  const be = tradableEvent.betting_event;
  const state = snapshot.json?.state || {};
  const signal = state.tennis_match_signal || {};
  const panel = panelProps();
  const bits = be.full_name.split(" v ").map((s) => s.trim());
  const grid = { md: 12 };
  const home = bits[0] || "home";
  const away = bits[1] || "away";

  return (
    <Grid container spacing={1}>
      <Grid item {...grid}>
        <Panel {...panel}>
          <Ev be={be} signal={signal} />
        </Panel>
      </Grid>
      <Grid item {...grid}>
        <Panel {...panel}>
          <Model signal={signal} home={home} away={away} />
        </Panel>
      </Grid>
    </Grid>
  );
};

const Ev = ({ be, signal }: { be: any; signal: any }) => {
  const ev = +(signal.ev || 0);
  const timeDecay = +(signal.time_decay || 0);
  const minutes = 90 - 90 * (signal.match?.event_time || 0);
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>time</TableCell>
          <TableCell>{minutes.toFixed(0)} minutes</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>ev</TableCell>
          <TableCell>
            <ColorMoney amount={+ev.toFixed(2)} currency="gbp" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>time decay</TableCell>
          <TableCell>
            <ColorMoney amount={+timeDecay.toFixed(2)} currency="gbp" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const Model = ({
  signal,
  home,
  away,
}: {
  signal: any;
  home: string;
  away: string;
}) => {
  const match = signal.match || {};
  const matchSets = match.sets || [];
  const sets = Array.from({ length: match.best_of }, (_, i) => `Set ${i + 1}`);
  const price = signal.home_win_last_price || {};
  const prob =
    0.5 * (asNumber(price.bid?.probability) + asNumber(price.ask?.probability));
  const players = [
    {
      name: home,
      prob,
      ewma: asNumber(signal.home_serv_prob_ewma),
      sets: sets.map((ignored: any, i: number) =>
        asNumberOr(matchSets[i]?.home, ""),
      ),
      points: match.current_game?.home,
      ...match.players?.home,
    },
    {
      name: away,
      prob: 1 - prob,
      ewma: asNumber(signal.away_serv_prob_ewma),
      sets: sets.map((ignored: any, i: number) =>
        asNumberOr(matchSets[i]?.away, ""),
      ),
      points: match.current_game?.away,
      ...match.players?.away,
    },
  ];
  const percent = format(".2%");
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Players</TableCell>
          <TableCell align="right">Win Prob</TableCell>
          <TableCell align="right">Serve Prob</TableCell>
          <TableCell align="right">Serve EWMA</TableCell>
          {sets.map((set, index) => (
            <TableCell align="right" key={index}>
              {set}
            </TableCell>
          ))}
          <TableCell align="right">Points</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {players.map((player: any, index: number) => (
          <TableRow key={index}>
            <TableCell>
              <Player player={player} />
            </TableCell>
            <TableCell align="right">{percent(player.prob)}</TableCell>
            <TableCell align="right">{percent(player.serve_prob)}</TableCell>
            <TableCell align="right">{percent(player.ewma)}</TableCell>
            {player.sets.map((set: any, index: number) => (
              <TableCell align="right" key={index}>
                {set}
              </TableCell>
            ))}
            <TableCell align="right">{player.points}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const Player = ({ player }: { player: any }) => {
  if (player.serving) return <Box component="span">{player.name} *</Box>;
  return <Box component="span">{player.name}</Box>;
};

export default TennisView;
