import { AccountIcon, AdminIcon } from "@fluidily/Components/Icons";
import MenuEntry from "@fluidily/Components/MenuEntry";
import RightBox from "@fluidily/Components/RightBox";
import { useStores } from "@fluidily/stores";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import WsStatus from "./WsStatus";

const UserMenu = ({ size }: { size?: any }) => {
  const { accountStore } = useStores();
  size = size || "medium";

  const items = [
    {
      id: "user-account-menu",
      title: "User menu",
      Icon: AccountIcon,
      Items: (props: any) => {
        const [ignored, render] = React.useState({});
        const { handleClose } = props;
        const { authStore, userStore } = useStores();
        const signOut = async () => {
          await authStore.logout();
          render({});
        };
        if (!userStore.current) return <Navigate to="/" />;
        return (
          <>
            <MenuItem onClick={handleClose} component={Link} to="/account">
              Account
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/account/tokens"
            >
              API Tokens
            </MenuItem>
            <MenuItem onClick={signOut}>Sign out</MenuItem>
          </>
        );
      },
    },
  ];

  if (accountStore.opsOrg) {
    const crumbs = [
      {
        text: "Tasks",
        to: "/admin/tasks",
      },
      {
        text: "Services",
        to: "/admin/services",
      },
      {
        text: "Leagues",
        to: "/admin/leagues",
      },
    ];
    items.push({
      id: "user-ops-menu",
      title: "Admin menu",
      Icon: AdminIcon,
      Items: (props: any) => {
        const { handleClose } = props;
        return (
          <>
            {crumbs.map((c: any) => (
              <MenuItem
                onClick={handleClose}
                component={Link}
                to={c.to}
                key={c.to}
              >
                {c.text}
              </MenuItem>
            ))}
          </>
        );
      },
    });
  }

  return (
    <RightBox>
      <WsStatus size={size} />
      {items.map((menu: any, index: number) => (
        <MenuEntry key={index} {...menu} size={size} />
      ))}
    </RightBox>
  );
};

export default UserMenu;
