import Search from "@fluidily/Views/Search";
import { SecurityKey, securityFullName } from "@fluidily/stores";
import { ControlField, SchemaEntryProps } from "@metablock/react";
import { Chip } from "@mui/material";
import React from "react";

const SecurityKeysInput = ({ name, form, schema }: SchemaEntryProps) => {
  const [securityKeys, _setSecurityKeys] = React.useState<SecurityKey[]>(
    form.data[name] || [],
  );
  const { title, description } = schema;
  const label = title || description || name;

  const setSecurityKeys = (newSecurityKeys: SecurityKey[]) => {
    form.setValue(name, newSecurityKeys);
    _setSecurityKeys(newSecurityKeys);
  };

  const selectSecurityKey = (event: any, newValue: any) => {
    if (!newValue) return;
    const securityKey = SecurityKey.fromFullName(newValue.code);
    if (
      securityKeys.find(
        (sk) => securityFullName(sk) === securityFullName(securityKey),
      )
    )
      return;
    const newSecurityKeys = [...securityKeys, securityKey];
    setSecurityKeys(newSecurityKeys);
  };

  const removeSecurityKey = (securityKey: SecurityKey) => {
    const newSecurityKeys = securityKeys.filter(
      (sk) => securityFullName(sk) !== securityFullName(securityKey),
    );
    setSecurityKeys(newSecurityKeys);
  };

  return (
    <ControlField name={name} form={form} margin={form.margin} fullWidth>
      <Search sx={{ padding: 0 }} onChange={selectSecurityKey} label={label} />
      {securityKeys.map((sk) => (
        <Chip
          key={securityFullName(sk)}
          label={securityFullName(sk)}
          onDelete={() => removeSecurityKey(sk)}
        />
      ))}
    </ControlField>
  );
};

export default SecurityKeysInput;
