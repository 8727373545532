import { SchemaEntryProps, TextField } from "@metablock/react";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Slider from "@mui/material/Slider";
import React from "react";
import Fieldset from "./Fieldset";

const isNumber = (value: any): boolean => {
  try {
    return value.constructor === Number;
  } catch (e) {
    return false;
  }
};

let id_ = 0;
const makeId = () => {
  id_ += 1;
  return id_;
};

const NumberSchema: React.FC<SchemaEntryProps> = (prop: SchemaEntryProps) => {
  const { name, schema, form, marks, ...extra } = prop;
  const { description } = schema;
  const [ignored, render] = React.useState<any>();
  const value = form.data[name];
  const sliderExtra = { ...extra };
  if (marks) sliderExtra.marks = true;

  const handleSliderChange = (event: any, newValue: any) => {
    form.setValue(name, newValue);
    render({});
  };

  const handleInputChange = (event: any) => {
    handleSliderChange(
      event,
      event.target.value === "" ? "" : Number(event.target.value),
    );
  };

  if (isNumber(schema.minimum) && isNumber(schema.maximum)) {
    const id = `${name}${makeId()}`;
    return (
      <Fieldset name={name} description={description}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Slider
              defaultValue={schema.default || schema.minimum}
              aria-labelledby={id}
              onChange={handleSliderChange}
              value={value}
              name={name}
              min={schema.minimum}
              max={schema.maximum}
              {...sliderExtra}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              margin="dense"
              fullWidth
              onChange={handleInputChange}
              value={value}
              inputProps={{
                min: schema.minimum,
                max: schema.maximum,
                type: "number",
                "aria-labelledby": "input-slider",
                ...extra,
              }}
            />
          </Grid>
        </Grid>
      </Fieldset>
    );
  } else
    return (
      <TextField
        fullWidth
        name={name}
        variant="outlined"
        margin="normal"
        label={description || name}
        placeholder={description || name}
        type="number"
        form={form}
        value={value}
        min={schema.minimum}
        max={schema.maximum}
        {...extra}
      />
    );
};

export default NumberSchema;
