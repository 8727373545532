import { onError422 } from "@fluidily/Forms";
import { StrategyMarket, useStores } from "@fluidily/stores";
import {
  REGIONS,
  SERVICES,
  STRATEGIES,
  asOptions,
} from "@fluidily/utils/options";
import { CrudForm, Crumb } from "@metablock/react";
import React from "react";
import OrgView from "../Views/OrgView";

const AddMarket = ({ left }: { left: Crumb[] }) => {
  return (
    <OrgView title="create new market" left={left}>
      <AddEditMarket />
    </OrgView>
  );
};

export const AddEditMarket = ({ market }: { market?: StrategyMarket }) => {
  const { fluidily, messageStore } = useStores();
  const schemaLoader = fluidily.schemaLoader("StrategyMarketCreate");

  const getSchema = async () => {
    return await schemaLoader();
  };

  const submit = async (data: any, changed: Record<string, any>) => {
    if (Object.keys(changed).length > 0) {
      if (market) {
        return await fluidily.markets.update(market.id, data);
      } else {
        return await fluidily.markets.create(data);
      }
    }
  };

  const fieldCallback = (name: string, props: any, schema: any) => {
    if (name.substring(name.length - 13) === "security_keys") {
      schema.format = "security_keys";
    } else if (name === "params") {
      schema.format = "params";
    } else if (name === "region") {
      props.options = asOptions(REGIONS);
    } else if (name === "strategy") {
      props.options = asOptions(STRATEGIES);
    } else if (name === "service_name") {
      props.options = asOptions(SERVICES);
    }
    return props;
  };

  const label = market ? "update market" : "create new market";

  return (
    <CrudForm
      defaults={market}
      schema={getSchema}
      submit={submit}
      label={label}
      fieldCallback={fieldCallback}
      onError422={onError422}
      onSuccess={(a: StrategyMarket) => {
        messageStore.success(`Market ${a.name} saved`);
      }}
      onError={(e: string) => {
        messageStore.error(e);
      }}
    />
  );
};

export default AddMarket;
