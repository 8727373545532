import { urlQuery } from "@metablock/core";

class HttpComponent {
  // add metablock JWT header to the headers dictionary
  cli: any;

  constructor(cli: any) {
    this.cli = cli;
  }

  urlQuery(url: string, query: any): string {
    return urlQuery(url, query);
  }
}

export default HttpComponent;
