import { Country } from "../protos";
import HttpComponent from "./httpComponent";

const topCountry: string[] = ["ww", "us", "gb", "de", "es", "fr", "it", "au"];

class Countries extends HttpComponent {
  _cache: Country[] = [];

  async all(): Promise<Country[]> {
    if (this._cache.length === 0) {
      const response = await this.cli.get(`${this.cli.apiUrl}/countries`);
      const countries = response.data as Country[];
      this._cache = countries.sort((a, b) => {
        const indexa = topCountry.indexOf(a.alpha_2.toLowerCase());
        const indexb = topCountry.indexOf(b.alpha_2.toLowerCase());
        if (indexa > -1 && indexb > -1) {
          return indexa - indexb;
        } else if (indexa > -1) {
          return -1;
        } else if (indexb > -1) {
          return 1;
        } else {
          return a > b ? -1 : 1;
        }
      });
    }
    return this._cache;
  }

  async countryMap(): Promise<{ [key: string]: Country }> {
    const countries = await this.all();
    const countryMap: { [key: string]: Country } = {};
    countries.forEach((country) => {
      countryMap[country.alpha_2.toLowerCase()] = country;
    });
    return countryMap;
  }
}

export default Countries;
