import { DataApi, UserOrg } from "@metablock/core";
import BaseStore from "./base";
import { Fluidily, Task } from "./client";
import { LeagueSeason } from "./protos";

class OpsUser extends BaseStore {
  org: UserOrg;

  constructor(fluidily: Fluidily, org: UserOrg) {
    super(fluidily);
    this.org = org;
  }

  options(options?: Record<string, any>) {
    if (!options) options = {};
    const { headers = {} } = options;
    headers["x-fluidily-org-id"] = this.org.org_id;
    return { ...options, headers };
  }

  taskLoader(query?: Record<string, any>): DataApi<Task> {
    return this.fluidily.loader(
      `${this.fluidily.apiUrl}/tasks`,
      query,
      this.options(),
    );
  }

  async taskUpdate(name: string, body: Record<string, any>): Promise<Task> {
    const url = `${this.fluidily.apiUrl}/tasks/${name}`;
    const response = await this.fluidily.patch(url, this.options({ body }));
    return response.data as Task;
  }

  async leagueUpdate(id: number, body: any): Promise<LeagueSeason> {
    const url = `${this.fluidily.apiUrl}/leagues/${id}`;
    const response = await this.fluidily.patch(url, this.options({ body }));
    return response.data as LeagueSeason;
  }
}

export default OpsUser;
