import { DataApi } from "@metablock/core";
import { Security } from "../protos";
import HttpComponent from "./httpComponent";

class Securities extends HttpComponent {
  loader(query?: any): DataApi<Security> {
    return this.cli.loader(`${this.cli.apiUrl}/securities`, query);
  }
}

export default Securities;
