import { default as DashboardConfig } from "./DashboardConfig";
import { default as ForexCross } from "./ForexCross";
import { default as MiniChart } from "./MiniChart";
import { ATPRanking } from "./TennisPlayers";

const DashboardPanels: Record<string, any> = {
  DashboardConfig,
  ATPRanking,
  ForexCross,
  MiniChart,
};

export default DashboardPanels;
