import Page from "@fluidily/Views/Page";
import { useCountry } from "@fluidily/hooks";
import { OpsUser } from "@fluidily/stores";
import React from "react";
import { Route, Routes } from "react-router-dom";
import League from "./League";
import Leagues from "./Leagues";

const LeaguesHome = ({ ops }: { ops: OpsUser }) => {
  const countryMap = useCountry();
  if (!countryMap) return null;
  return (
    <Routes>
      <Route
        path=":leagueId"
        element={<League ops={ops} countryMap={countryMap} />}
      />
      <Route
        path=""
        element={
          <Page title="Leagues">
            <Leagues useUrl={true} ops={ops} countryMap={countryMap} />
          </Page>
        }
      />
    </Routes>
  );
};

export default LeaguesHome;
