import { useStores } from "@fluidily/stores";
import { User } from "@metablock/core";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import React from "react";

const UserDefaultOrgs = () => {
  const { userStore, accountStore } = useStores();
  const user: User = userStore.current as User;
  const defaultOrg = user.additional_info?.default_org || "";
  const orgs = accountStore.orgsByName;
  const changeDefaultOrg = async (event: any) => {
    const additional_info = {
      ...user.additional_info,
      default_org: event.target.value,
    };
    await userStore.updateUser({ additional_info });
  };
  return (
    <FormControl>
      <Typography component="label" variant="h6" id="default-user-org">
        Default Organization
      </Typography>
      <RadioGroup
        aria-labelledby="default-user-org"
        defaultValue={defaultOrg}
        name="default-org-group"
        onChange={changeDefaultOrg}
      >
        {Object.values(orgs).map((org: any) => (
          <FormControlLabel
            key={org.org_id}
            value={org.org_name}
            control={<Radio />}
            label={org.org_name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default UserDefaultOrgs;
