import { HttpClient, HttpResponse, Paginated } from "@metablock/core";
import Accounts from "./accounts";
import Alerts from "./alerts";
import BettingEvents from "./betting_events";
import Countries from "./countries";
import Dashboards from "./dashboards";
import Dev from "./dev";
import Entities from "./entities";
import Exchanges from "./exchanges";
import Leagues from "./leagues";
import Markets from "./markets";
import Players from "./players";
import createSchema from "./schema";
import Securities from "./securities";
import Sport from "./sport";

export interface FluidilyOptions {
  baseUrl?: string;
  name?: string;
  jwt?: string;
}

export class Fluidily extends HttpClient {
  apiUrl: string;
  accounts: Accounts;
  betting_events: BettingEvents;
  countries: Countries;
  exchanges: Exchanges;
  alerts: Alerts;
  entities: Entities;
  dashboards: Dashboards;
  markets: Markets;
  players: Players;
  leagues: Leagues;
  securities: Securities;
  sport: Sport;
  dev: Dev;
  orgId: string;
  private _spec?: any;

  constructor(options: FluidilyOptions) {
    super(options.name || "fluidily");
    this.apiUrl = options.baseUrl || "https://api.fluidily.com/v1";
    this.accounts = new Accounts(this);
    this.betting_events = new BettingEvents(this);
    this.countries = new Countries(this);
    this.exchanges = new Exchanges(this);
    this.markets = new Markets(this);
    this.alerts = new Alerts(this);
    this.entities = new Entities(this);
    this.dashboards = new Dashboards(this);
    this.leagues = new Leagues(this);
    this.players = new Players(this);
    this.securities = new Securities(this);
    this.sport = new Sport(this);
    this.dev = new Dev(this);
    this.orgId = "";
  }

  get jwt(): string {
    return window.localStorage.getItem("metablock-key") || "";
  }

  schemaLoader(name: string) {
    return async () => {
      if (!this._spec) {
        const response = await this.get(`${this.apiUrl}/openapi.json`);
        this._spec = response.data;
      }
      return createSchema(this._spec.components.schemas[name], this._spec);
    };
  }

  getDefaultHeaders(): Record<string, string> {
    const headers: Record<string, string> = { ...this.defaultHeaders };
    if (this.jwt) headers["authorization"] = `Bearer ${this.jwt}`;
    if (this.orgId) headers["x-fluidily-org-id"] = this.orgId;
    return headers;
  }

  async getList<DataType>(
    url: string,
    options?: any,
  ): Promise<Paginated<DataType>> {
    const response = await this.get(url, options);
    return paginatedResponse<DataType>(response);
  }
}

const paginatedResponse = <T>(response: HttpResponse): Paginated<T> => {
  const data = response.data as T[];
  const cursor = response.headers.get("x-cursor");
  const links: Record<string, any> = {};
  if (cursor) {
    const url = new URL(response.response.url);
    Array.from(url.searchParams.keys()).forEach((key: string) => {
      url.searchParams.delete(key);
    });
    url.searchParams.set("cursor", cursor);
    links.next = url;
  }
  return new Paginated<T>(data, data.length, links);
};
