import { ExchangeId } from "@fluidily/Views/ExchangeSymbol";
import {
  BettingContract,
  BettingEvent,
  BettingMarket,
  TradableBettingEvent,
} from "@fluidily/stores";
import { round } from "@fluidily/utils/money";
import { asNumber } from "@fluidily/utils/numbers";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { blueGrey } from "@mui/material/colors";
import { format } from "d3-format";
import { interpolateBlues as volumeColor } from "d3-scale-chromatic";
import React from "react";

const roundSignal = round(2);
const percent = format(".2%");

interface ContractSummary {
  contract: BettingContract;
  name: string;
  volume: number;
  trades: number;
  vwap: number;
  spread: number;
}

class MarketSummary {
  name: string;
  bettingEvent: BettingEvent;
  bettingMarket: BettingMarket;
  contracts: Record<string, ContractSummary>;
  data: ContractSummary[];

  constructor(
    name: string,
    bettingMarket: BettingMarket,
    bettingEvent: BettingEvent,
  ) {
    this.name = name;
    this.bettingEvent = bettingEvent;
    this.bettingMarket = bettingMarket;
    this.contracts = {};
    this.data = bettingMarket.betting_contracts.map(
      (contract: BettingContract) => {
        const key = `${bettingMarket.exchange_id}:${contract.exchange_id}`;
        const d = {
          contract,
          name: contract.contract_type,
          volume: 0,
          trades: 0,
          vwap: 0,
          spread: 0,
        };
        this.contracts[key] = d;
        return d;
      },
    );
  }

  get volume() {
    return this.data.reduce((acc: number, d: ContractSummary) => {
      return acc + d.volume;
    }, 0);
  }

  get avgVolume() {
    return this.volume / this.data.length;
  }

  get maxVolume() {
    return this.data.reduce((acc: number, d: ContractSummary) => {
      return Math.max(acc, d.volume);
    }, 0);
  }
}

const MarketsView = ({
  tradableEvent,
  snapshot,
}: {
  tradableEvent: TradableBettingEvent;
  snapshot: any;
}) => {
  if (!snapshot) return null;
  const be = tradableEvent?.betting_event || {};
  const bettingMarkets = marketMap(be?.betting_markets || []);
  const markets: Record<string, MarketSummary> = {};
  const state = snapshot.json?.state || {};
  Object.keys(state).forEach((key: string) => {
    const bits = key.split(":");
    if (bits.length === 3 && bits[0] === "market") {
      const name = bits[1];
      const bettingMarket = bettingMarkets[name];
      if (bettingMarket) {
        const market =
          markets[name] || new MarketSummary(name, bettingMarket, be);
        updateMarket(market, bits[2], state[key]);
        markets[name] = market;
      }
    }
  });
  const maxVolume = Object.values(markets).reduce(
    (v, market: MarketSummary) => {
      return Math.max(market.maxVolume, v);
    },
    0.0001,
  );
  const market_list = Object.values(markets).sort(
    (a, b) => b.avgVolume - a.avgVolume,
  );
  const head = { backgroundColor: blueGrey[900], fontSize: "110%" };

  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={head}>contract</TableCell>
          <TableCell align="right" sx={head}>
            total volume
          </TableCell>
          <TableCell align="right" sx={head}>
            ewma volume
          </TableCell>
          <TableCell align="right" sx={head}>
            ewma trades
          </TableCell>
          <TableCell align="right" sx={head}>
            vwap
          </TableCell>
          <TableCell align="right" sx={head}>
            spread
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {market_list.map((market: any) => (
          <MarketView key={market.name} market={market} maxVolume={maxVolume} />
        ))}
      </TableBody>
    </Table>
  );
};

const MarketView = ({
  market,
  maxVolume,
}: {
  market: MarketSummary;
  maxVolume: number;
}) => {
  return (
    <>
      <TableRow sx={{ backgroundColor: "transparent" }}>
        <TableCell align="center" colSpan={6} sx={{ fontSize: "110%" }}>
          <ExchangeId
            text={market.name.replaceAll("_", " ")}
            betting_event={market.bettingEvent}
            betting_market={market.bettingMarket}
          />
        </TableCell>
      </TableRow>
      {market.data.map((d: ContractSummary) => {
        const s = Math.log(d.volume || 0.000001) / Math.log(maxVolume);
        const sx = {
          backgroundColor: volumeColor(s),
          color: s > 0.5 ? blueGrey[50] : blueGrey[900],
        };
        return (
          <TableRow hover key={d.name}>
            <TableCell sx={sx}>{d.name}</TableCell>
            <TableCell align="right" sx={sx}>
              {roundSignal(+d.contract.volume)}
            </TableCell>
            <TableCell align="right" sx={sx}>
              {roundSignal(d.volume)}
            </TableCell>
            <TableCell align="right" sx={sx}>
              {roundSignal(d.trades)}
            </TableCell>
            <TableCell align="right" sx={sx}>
              {roundSignal(d.vwap)}
            </TableCell>
            <TableCell align="right" sx={sx}>
              {percent(d.spread)}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

const updateMarket = (market: MarketSummary, signal: string, data: any) => {
  if (signal === "market_trades") {
    updateSignal(
      market,
      data.volume,
      (contract: ContractSummary, key: string) => {
        contract.volume = asNumber(data.volume[key]?.average);
        contract.trades = asNumber(data.trade_count[key]?.average);
        contract.vwap = asNumber(data.vwap[key]?.average);
      },
    );
  } else if (signal === "market_spread") {
    updateSignal(
      market,
      data.max_spread,
      (contract: ContractSummary, key: string) => {
        contract.spread = asNumber(data.max_spread[key]?.average);
      },
    );
  }
};

const updateSignal = (market: MarketSummary, data: any, callback: any) => {
  Object.keys(data).forEach((key: string) => {
    const contract = market.contracts[key];
    if (contract) callback(contract, key);
  });
};

const marketMap = (markets: BettingMarket[]) => {
  return markets.reduce((acc: any, market: BettingMarket) => {
    const params = JSON.parse(market.market_type_param);
    let name = market.market_type;
    if (name === "goals_over_under") name = `${name}_${params.target}`;
    acc[name] = market;
    return acc;
  }, {});
};

export default MarketsView;
