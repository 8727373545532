import { booleanFormatter, MonoBox } from "@fluidily/Components";
import rowDate from "@fluidily/Components/dateFormatter";
import { ChannelId, Security, useStores } from "@fluidily/stores";
import { formatDateTime } from "@fluidily/utils/dates";
import { getMarket, Value } from "@fluidily/utils/market";
import { round } from "@fluidily/utils/money";
import { EXCHANGES, INSTRUMENT_TYPES } from "@fluidily/utils/options";
import {
  ApiDataGrid,
  DataGridBoolFilter,
  DataGridNoFilter,
  DataGridSelectFilter,
  Link,
  useDataGridFilters,
} from "@metablock/react";
import Box from "@mui/material/Box";
import React from "react";
import { useWebsocket } from "../hooks";
import ExchangeSymbol from "./ExchangeSymbol";

const round3 = round(3);

const MidPrice = ({ row }: { row: Security }) => {
  const [last, setPrice] = React.useState<Value>(new Value(0));
  useWebsocket((msg: any) => {
    if (msg) setPrice(last.update(0.5 * (+msg.bid.price + msg.ask.price)));
  }, ChannelId.orderBookTop(row.security_key));
  const sx: any = { fontWeight: 700 };
  if (last.direction) {
    sx.color = last.direction > 0 ? "success.dark" : "error.dark";
  }
  return <Box sx={sx}>{last.mid ? last.mid : ""}</Box>;
};

const tickerFormatter = (field: string) => {
  return ({ row }: { row: Security }) => {
    const [ignored, setState] = React.useState<Value>(new Value(0));
    const i = getMarket(row.security_key.name).getInstrument(
      row.security_key.exchange,
    );
    React.useEffect(() => {
      const instrument = getMarket(row.security_key.name).getInstrument(
        row.security_key.exchange,
      );
      instrument.addCallback(setState);
      return () => {
        instrument.removeCallback(setState);
      };
    }, [row.security_key.name, row.security_key.exchange]);
    return <Box sx={{ textAlign: "right" }}>{round3(i.ticker[field])}</Box>;
  };
};

const Securities = (props: any) => {
  const {
    filters,
    columns,
    instrumentUrl,
    exchangeUrl,
    useUrl = false,
    ...extra
  } = props;
  const { fluidily } = useStores();
  const dataGridFilters = useDataGridFilters(useUrl, {
    tradable: "yes",
    ...filters,
  });

  // This is required to force re-render when filters prop change
  React.useEffect(() => {
    dataGridFilters.setSome(filters);
  }, [filters]);

  const api = fluidily.securities.loader();
  const defaultCols = React.useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        minWidth: 130,
        renderHeaderCell: DataGridNoFilter,
        resizable: true,
        renderCell: ({ row }: any) =>
          instrumentUrl ? (
            <Link to={`${instrumentUrl}/${row.security_key.name}`}>
              {row.security_key.name}
            </Link>
          ) : (
            row.security_key.name
          ),
      },
      {
        key: "exchange",
        name: "Exchange",
        resizable: true,
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={EXCHANGES}
            {...p}
          />
        ),
        renderCell: ({ row }: { row: Security }) =>
          exchangeUrl ? (
            <Link to={`${exchangeUrl}/${row.security_key.exchange}`}>
              {row.security_key.exchange}
            </Link>
          ) : (
            row.security_key.exchange
          ),
      },
      {
        key: "security_type",
        name: "Type",
        resizable: true,
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={INSTRUMENT_TYPES}
            {...p}
          />
        ),
      },
      {
        key: "mid",
        name: "Mid Price",
        resizable: true,
        renderCell: MidPrice,
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "volume",
        name: "Volume",
        resizable: true,
        renderCell: tickerFormatter("volume"),
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "contract_value",
        name: "Contract value",
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "multiplier",
        name: "Multiplier",
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "tick_size",
        name: "Tick Size",
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "lot_size",
        name: "Lot Size",
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "min_size",
        name: "Min Size",
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "tradable",
        name: "Tradable",
        maxWidth: 100,
        renderCell: booleanFormatter("tradable"),
        renderHeaderCell: (p: any) => (
          <DataGridBoolFilter dataGridFilters={dataGridFilters} {...p} />
        ),
      },
      {
        key: "inverse",
        name: "Inverse",
        maxWidth: 100,
        renderCell: booleanFormatter("inverse"),
        renderHeaderCell: (p: any) => (
          <DataGridBoolFilter dataGridFilters={dataGridFilters} {...p} />
        ),
      },
      {
        key: "quanto",
        name: "Quanto",
        maxWidth: 100,
        renderCell: booleanFormatter("quanto"),
        renderHeaderCell: (p: any) => (
          <DataGridBoolFilter dataGridFilters={dataGridFilters} {...p} />
        ),
      },
      {
        key: "exchange_symbol",
        name: "Exchange Code",
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
        renderCell: ({ row }: { row: Security }) => (
          <ExchangeSymbol security={row} />
        ),
      },
      {
        key: "currency",
        name: "Settle",
        maxWidth: 100,
        renderHeaderCell: DataGridNoFilter,
        renderCell: ({ row }: { row: Security }) => row.settlement.currency,
      },
      {
        key: "expiry",
        name: "Expiry",
        resizable: true,
        minWidth: 120,
        renderHeaderCell: DataGridNoFilter,
        renderCell: rowDate(
          (row: Security) => row.settlement.expiry,
          formatDateTime,
        ),
      },
    ],
    [dataGridFilters.filters],
  );

  const cols: any = React.useMemo(() => {
    return columns
      ? defaultCols.filter((col: any) => columns.indexOf(col.key) > -1)
      : defaultCols;
  }, [columns, defaultCols]);

  return (
    <MonoBox>
      <ApiDataGrid
        api={api}
        columns={cols}
        headerRowHeight={80}
        className="rdg-dark"
        style={{ height: "100vh" }}
        dataGridFilters={dataGridFilters}
        search
        {...extra}
      />
    </MonoBox>
  );
};

export default Securities;
