import rowDate from "@fluidily/Components/dateFormatter";
import { useWebsocket } from "@fluidily/hooks";
import {
  ChannelId,
  Snapshot,
  StrategyMarket,
  useStores,
} from "@fluidily/stores";
import { gridFullHeight } from "@fluidily/theme";
import { formatDateTime, formatTimestamp } from "@fluidily/utils/dates";
import {
  REGIONS,
  SERVICES,
  STRATEGIES,
  TRADING_STATES,
} from "@fluidily/utils/options";
import {
  ApiDataGrid,
  Crumb,
  DataGridNoFilter,
  DataGridSelectFilter,
  Link,
  useDataGridFilters,
} from "@metablock/react";
import Box from "@mui/material/Box";
import React from "react";
import OrgView from "../Views/OrgView";

type SnapshotFn = (snapshot: Snapshot) => any;

const FromSnapshot = ({
  market,
  fn,
}: {
  market: StrategyMarket;
  fn: SnapshotFn;
}) => {
  const channel = new ChannelId({ channel: "snapshot" });
  const [snapshot, setSnapshot] = React.useState<Snapshot | null>(null);
  useWebsocket((snapshot?: Snapshot) => {
    if (
      snapshot &&
      snapshot.strategy_market &&
      snapshot.strategy_market.id === market.id
    ) {
      setSnapshot(snapshot);
    }
  }, channel);
  return snapshot ? fn(snapshot) : null;
};

const fromSnapshot = (fn: SnapshotFn) => {
  return ({ row }: { row: StrategyMarket }) => {
    return <FromSnapshot market={row} fn={fn} />;
  };
};

const Markets = ({ basePath, left }: { basePath: string; left: Crumb[] }) => {
  const { fluidily } = useStores();
  const api = fluidily.markets.loader();
  const dataGridFilters = useDataGridFilters(true);

  const columns = React.useMemo(
    () => [
      {
        key: "id",
        name: "ID",
        maxWidth: 60,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "name",
        name: "Name",
        minWidth: 130,
        resizable: true,
        renderCell: ({ row }: { row: StrategyMarket }) => (
          <Link to={`${basePath}/${row.id}`}>{row.name}</Link>
        ),
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "strategy",
        name: "strategy",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={STRATEGIES}
            {...p}
          />
        ),
      },
      {
        key: "trading_state",
        name: "state",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={TRADING_STATES}
            {...p}
          />
        ),
      },
      {
        key: "is_ready",
        name: "ready",
        maxWidth: 80,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "service_name",
        name: "Service",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={SERVICES}
            {...p}
          />
        ),
      },
      {
        key: "running_service_uid",
        name: "Runner Service UID",
        resizable: true,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
        renderCell: fromSnapshot((snapshot: Snapshot) => snapshot.service.uid),
      },
      {
        key: "service_uid",
        name: "Private Service UID",
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "timestamp",
        name: "Updated",
        align: "right",
        minWidth: 140,
        renderCell: rowDate("timestamp", formatDateTime),
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "tick_timestamp",
        name: "Tick timestamp",
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
        renderCell: fromSnapshot((snapshot: Snapshot) => (
          <Box sx={{ textAlign: "right" }}>
            {formatTimestamp(snapshot.json.strategy?.last_tick_timestamp)}
          </Box>
        )),
      },
      {
        key: "inventory",
        name: "Inventory",
        resizable: true,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "region",
        name: "Region",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={REGIONS}
            {...p}
          />
        ),
      },
    ],
    [dataGridFilters.filters],
  );

  const rowHeight = ({ row }: { row: StrategyMarket }) => {
    return 40 * Math.max(row.security_keys.length, 1);
  };

  return (
    <OrgView title="markets" left={left}>
      <ApiDataGrid
        sx={gridFullHeight()}
        columns={columns}
        api={api}
        rowHeight={rowHeight}
        headerRowHeight={80}
        style={{ flex: 1 }}
        dataGridFilters={dataGridFilters}
      />
    </OrgView>
  );
};

export default Markets;
