import { useStores } from "@fluidily/stores";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Menu, MenuItem, MenuList } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";

const OrgMenu = ({ size }: { size?: any }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { accountStore } = useStores();
  const org = accountStore.org;
  const section = "";
  const path = (name: string) =>
    section ? `/orgs/${name}/${section}` : `/orgs/${name}`;
  const label = org ? org.org_name : "orgs";

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = "org-choice";

  return (
    <Box>
      <Button
        id={id}
        variant="contained"
        aria-controls={open ? "strategy-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        size={size}
      >
        {label}
      </Button>
      <Menu
        id="strategy-menu"
        MenuListProps={{
          "aria-labelledby": id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList>
          {Object.keys(accountStore.orgsByName).map((name: string) => (
            <MenuItem
              key={name}
              onClick={handleClose}
              component={Link}
              to={path(name)}
            >
              {name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default observer(OrgMenu);
