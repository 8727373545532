import DLFactory from "@fluidily/Components/DescriptionList";
import { blueGrey } from "@mui/material/colors";

export const panelProps = () => ({
  sx: { backgroundColor: blueGrey[900] },
  headerSx: { paddingBottom: 0, paddingTop: 1 },
  titleTypographyProps: { variant: "h6" },
});

export const Dl = DLFactory({ sm: 4 });
