import { useStores } from "@fluidily/stores";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import React from "react";
import ReactJson from "react-json-view";

const DashboardConfig = () => {
  const { webStore } = useStores();
  const sx = {
    fontSize: "0.8rem",
    overflow: "auto",
  };
  return (
    <Box sx={sx}>
      <ReactJson src={webStore.dashboard} theme="tomorrow" />
    </Box>
  );
};

export default observer(DashboardConfig);
