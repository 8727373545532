import { breadcrumbs } from "@fluidily/utils/breadcrumbs";
import { Crumb } from "@metablock/react";
import { action, makeObservable, observable } from "mobx";

interface Target {
  type: string;
  name: string;
}

class WebStore {
  tabs: Crumb[] = [];
  crumbs: Crumb[] = [];
  title?: string = undefined;
  target?: Target = undefined;
  dashboard: Record<string, any> = {};

  constructor() {
    makeObservable(this, {
      setCrumbs: action,
      setTarget: action,
      setDashboard: action,
      crumbs: observable,
      target: observable,
      dashboard: observable,
    });
  }

  setCrumbs(crumbs?: Crumb[]): WebStore {
    this.crumbs = crumbs || breadcrumbs();
    return this;
  }

  setTabs(tabs?: Crumb[]): WebStore {
    this.tabs = tabs || [];
    return this;
  }

  setTitle(title?: string): WebStore {
    this.title = title;
    return this;
  }

  setDashboard(dashboard: Record<string, any>) {
    this.dashboard = dashboard;
  }

  setTarget(target?: Target) {
    this.target = target || undefined;
  }
}

export default WebStore;
