import { Account, useStores } from "@fluidily/stores";
import { CrudForm, SchemaEntry } from "@metablock/react";
import { Container } from "@mui/material";
import React from "react";
import OrgView from "../Views/OrgView";

const AddAccountApiKey = ({
  account,
  ...page
}: {
  account: Account;
  [x: string]: any;
}) => {
  return (
    <OrgView title={`add API key for account ${account.exchange}`} {...page}>
      <Container maxWidth="sm">
        <AddAccountApiKeyForm account={account} />
      </Container>
    </OrgView>
  );
};

const AddAccountApiKeyForm = ({ account }: { account: Account }) => {
  const { messageStore, fluidily } = useStores();

  const getSchema = async () => {
    const exchange = await fluidily.exchanges.get(account.exchange);
    return exchange.api_key_schema as SchemaEntry;
  };

  const onSubmit = async (payload: any) => {
    const body = {
      demo: account.demo,
      name: account.exchange,
      payload,
    };
    const apiKey = await fluidily.accounts.updateApiKey(account.id, body);
    messageStore.success(
      `API key ${apiKey.name} for ${account.exchange} created successfully`,
      3000,
    );
  };

  return <CrudForm schema={getSchema} submit={onSubmit} />;
};

export default AddAccountApiKey;
