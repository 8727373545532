import { NotFound } from "@metablock/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AccountRoutes from "./Account";
import Accounts from "./Accounts";
import AddAccount from "./AddAccount";
import nav from "./nav";

const AccountsRoutes = ({ basePath }: { basePath: string }) => {
  const left = nav(basePath);
  return (
    <Routes>
      <Route path="" element={<Accounts basePath={basePath} left={left} />} />
      <Route path="/new" element={<AddAccount left={left} />} />
      <Route
        path="/:accountId/*"
        element={<AccountRoutes basePath={basePath} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AccountsRoutes;
