import Page from "@fluidily/Views/Page";
import { Exchange } from "@fluidily/stores";
import { Typography } from "@mui/material";
import React from "react";
import ReactJson from "react-json-view";

const ExchangeHome = ({
  exchange,
  ...props
}: {
  exchange: Exchange;
  [x: string]: any;
}) => {
  return (
    <Page title={`Exchange ${exchange.name}`} {...props}>
      <Typography variant="h6" paragraph>
        {exchange.description}
      </Typography>
      <Typography variant="h6" paragraph>
        API Key Schema
      </Typography>
      <ReactJson src={exchange.api_key_schema} theme="tomorrow" />
    </Page>
  );
};

export default ExchangeHome;
