import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/system";
import React from "react";

const Outer = styled("div")({
  marginTop: "theme.spacing(5)",
  width: "100%",
  "& > * + *": {
    marginTop: "theme.spacing(2)",
  },
});

const Loading = () => {
  return (
    <Outer>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </Outer>
  );
};

export default Loading;
