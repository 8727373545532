import { NotFound } from "@metablock/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AddMarket from "./AddMarket";
import MarketHome from "./Market";
import Markets from "./Markets";
import nav from "./nav";

const MarketsRoutes = ({ basePath }: { basePath: string }) => {
  const left = nav(basePath);
  return (
    <Routes>
      <Route path="" element={<Markets basePath={basePath} left={left} />} />
      <Route path="/new" element={<AddMarket left={left} />} />
      <Route
        path="/:marketId/*"
        element={<MarketHome left={left} basePath={basePath} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default MarketsRoutes;
