import { booleanFormatter } from "@fluidily/Components";
import { LeagueSeason, countryOptions, useStores } from "@fluidily/stores";
import { BETTING_EVENT_TYPES } from "@fluidily/utils/options";
import {
  ApiDataGrid,
  DataGridBoolFilter,
  DataGridNoFilter,
  DataGridSelectFilter,
  Link,
  useDataGridFilters,
} from "@metablock/react";
import React from "react";
import { MatchingScore } from "./League";

const Leagues = (props: any) => {
  const { filters, countryMap, ops, useUrl = false, ...extra } = props;
  const [ignored, render] = React.useState<any>({});
  const { fluidily } = useStores();
  const dataGridFilters = useDataGridFilters(useUrl, {
    current: "yes",
    tracked: "yes",
    ...filters,
  });
  const today = new Date();
  const seasons: any[] = Array.from({ length: 20 }, (_, i) => {
    const value = today.getFullYear() - i;
    return { value, label: "" + value };
  });
  seasons.unshift({ value: "", label: "all" });
  const countries = countryOptions(countryMap);

  const trackLeague = async (row: any) => {
    await ops.leagueUpdate(row.id, { tracked: !row.tracked });
    render({});
  };

  // This is required to force re-render when filters prop change
  React.useEffect(() => {
    dataGridFilters.setSome(filters);
  }, [filters]);

  const api = fluidily.leagues.loader();
  const columns = React.useMemo(
    () => [
      {
        key: "full_name",
        name: "Full Name",
        minWidth: 130,
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
        renderCell: ({ row }: { row: LeagueSeason }) => (
          <Link to={`${row.id}`} underline="none">
            {row.full_name}
          </Link>
        ),
      },
      {
        key: "current",
        name: "Current",
        maxWidth: 100,
        renderCell: booleanFormatter("current", null, true),
        renderHeaderCell: (p: any) => (
          <DataGridBoolFilter dataGridFilters={dataGridFilters} {...p} />
        ),
      },
      {
        key: "tracked",
        name: "Tracked",
        maxWidth: 100,
        renderCell: booleanFormatter("tracked", trackLeague, true),
        renderHeaderCell: (p: any) => (
          <DataGridBoolFilter dataGridFilters={dataGridFilters} {...p} />
        ),
      },
      {
        key: "season",
        name: "Season",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={seasons}
            {...p}
          />
        ),
      },
      {
        key: "event_type",
        name: "Type",
        resizable: true,
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={BETTING_EVENT_TYPES}
            {...p}
          />
        ),
      },
      {
        key: "country",
        name: "Country",
        renderCell: ({ row }: { row: LeagueSeason }) => {
          const country = countryMap[row.country.toLocaleLowerCase() || "  "];
          return country ? country.name : row.country.toLocaleLowerCase();
        },
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={countries}
            {...p}
          />
        ),
      },
      {
        key: "betfair_matching_score",
        name: "Betfair match",
        renderHeaderCell: DataGridNoFilter,
        renderCell: ({ row }: { row: LeagueSeason }) => {
          const score = row.extra.betfair_matching_score;
          return (
            <MatchingScore score={score} sx={{ pr: 1, textAlign: "right" }} />
          );
        },
      },
      {
        key: "league_type",
        name: "League Type",
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "sex",
        name: "Sex",
        renderHeaderCell: DataGridNoFilter,
      },
      {
        key: "surface",
        name: "Surface",
        renderHeaderCell: DataGridNoFilter,
      },
    ],
    [dataGridFilters.filters],
  );

  return (
    <ApiDataGrid
      api={api}
      columns={columns}
      headerRowHeight={80}
      className="rdg-dark"
      fullHeight={true}
      dataGridFilters={dataGridFilters}
      search
      {...extra}
    />
  );
};

export default Leagues;
