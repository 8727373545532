import deleteEntry from "@fluidily/Views/deleteEntry";
import { useStores } from "@fluidily/stores";
import { formatTimeAgo } from "@fluidily/utils/dates";
import { ApiDataGrid } from "@metablock/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";

const Tokens = (props: any) => {
  const { metablock } = useStores();
  const [tokenInfo, ShowNewToken] = useState<any>({});
  const api = metablock.user.tokensLoader();

  const createToken = async () => {
    const result = await metablock.user.createToken();
    ShowNewToken(result);
  };

  const deleteToken = async (row: any) => {
    await metablock.user.deleteToken(row.id);
    ShowNewToken({});
  };

  const handleClose = () => {
    ShowNewToken({});
  };
  const columns: any[] = [
    { key: "id", name: "ID" },
    {
      key: "created",
      name: "Created",
      renderCell: ({ row }: any) => formatTimeAgo(new Date(row.created)),
    },
    {
      key: "last_access",
      name: "Last Used",
      renderCell: ({ row }: any) =>
        row.last_access ? formatTimeAgo(new Date(row.last_access)) : "never",
    },
    {
      key: "access_count",
      name: "Used",
    },
    deleteEntry(deleteToken, (row: any) => `Delete token ${row.id}`),
  ];

  return (
    <>
      <Box pb={2}>
        <Button color="primary" variant="contained" onClick={createToken}>
          Create new token
        </Button>
      </Box>
      <ApiDataGrid
        title="Your Fluidily API Tokens"
        columns={columns}
        api={api}
      />
      <Dialog
        open={tokenInfo.key !== undefined}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          New API Token created
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{tokenInfo.key}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Tokens;
