import { useStores } from "@fluidily/stores";
import { User } from "@metablock/core";
import { Page } from "@metablock/react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

const AccountHome = (props: any) => {
  const { userStore } = useStores();
  const user = userStore.current as User;
  const bits = [];
  if (user.first_name) bits.push(user.first_name);
  if (user.last_name) bits.push(user.last_name);
  const name = bits ? bits.join(" ") : user.username;

  return (
    <Page title={name}>
      <Box bgcolor="action.hover">
        <Container maxWidth="md">
          <Box pb={4} pt={4}>
            <Typography variant="h4" component="h1" paragraph>
              {name}
            </Typography>
            <Typography variant="subtitle2" component="h3" paragraph>
              {user.email}
            </Typography>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default AccountHome;
