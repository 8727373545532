import { BettingEvent, BettingMarket, Security } from "@fluidily/stores";
import Link from "@mui/material/Link";
import React from "react";

const EXCHANGES: Record<string, (security: Security) => string | null> = {
  deribit: (security: Security): string | null => {
    if (security.security_type == "SPOT")
      return `https://www.deribit.com/spot/${security.exchange_symbol}`;
    else if (security.security_type == "OPTION") {
      const bits = security.exchange_symbol.split("-");
      const maturity = `${bits[0]}-${bits[1]}`;
      return `https://www.deribit.com/options/${security.pair.base.toUpperCase()}/${maturity}/${
        security.exchange_symbol
      }`;
    } else return `https://www.deribit.com/futures/${security.exchange_symbol}`;
  },
  bitmex: (security: Security): string | null => {
    return `https://www.bitmex.com/app/trade/${security.exchange_symbol}`;
  },
};

const BETTING_EXCHANGES: Record<
  string,
  (betting_event: BettingEvent, betting_market?: BettingMarket) => string | null
> = {
  betfair: (
    betting_event: BettingEvent,
    betting_market?: BettingMarket,
  ): string | null => {
    const market = betting_market || betting_event.betting_markets[0];
    if (!market) return null;
    return `https://www.betfair.com/exchange/plus/${betting_event.event_type.toLowerCase()}/market/${
      market.exchange_id
    }`;
  },
};

const ExchangeSymbol = ({ security }: { security: Security }) => {
  const exchange = EXCHANGES[security.security_key.exchange];
  const url = exchange ? exchange(security) : null;
  return url ? (
    <Link href={url} target="_blank" rel="noopener">
      {security.exchange_symbol}
    </Link>
  ) : (
    security.exchange_symbol
  );
};

export const ExchangeId = ({
  betting_event,
  betting_market,
  text,
}: {
  betting_event: BettingEvent;
  betting_market?: BettingMarket;
  text?: string;
}) => {
  const exchange = BETTING_EXCHANGES[betting_event.security_key.exchange];
  const url = exchange ? exchange(betting_event, betting_market) : null;
  const label = text || betting_event.exchange_id;
  return url ? (
    <Link href={url} target="_blank" rel="noopener" underline="none">
      {label}
    </Link>
  ) : (
    label
  );
};

export default ExchangeSymbol;
