import WsIcon from "@mui/icons-material/WifiTethering";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useWebsocket } from "../hooks";
import { ChannelId } from "../stores";

const WsStatus = ({ size }: { size?: any }) => {
  const [status, setStatus] = React.useState<any>({});
  const channel = new ChannelId({ channel: "server_info" });

  useWebsocket((msg: any) => {
    if (msg) setStatus(msg);
  }, channel);

  const color = status.started ? "success" : "error";
  const statusText = status.started
    ? `Websocket connected ${Math.round(0.001 * status.started)} seconds`
    : status.error
    ? status.error
    : "Websocket disconnected";
  return (
    <IconButton size={size || "medium"}>
      <Tooltip title={statusText} placement="bottom">
        <WsIcon color={color} />
      </Tooltip>
    </IconButton>
  );
};

export default WsStatus;
