import DLFactory from "@fluidily/Components/DescriptionList";
import booleanFormatter, {
  BooleanIcon,
} from "@fluidily/Components/booleanFormatter";
import deleteEntry from "@fluidily/Views/deleteEntry";
import { Account, ApiKey, useStores } from "@fluidily/stores";
import { StaticData } from "@metablock/core";
import { ApiDataGrid, Crumb, Link, NotFound } from "@metablock/react";
import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import OrgView from "../Views/OrgView";
import AddAccount from "./AddAccount";
import AddAccountApiKey from "./AddApiKey";
import nav from "./nav";

const AccountRoutes = ({ basePath }: { basePath: string }) => {
  const { fluidily } = useStores();
  const { accountId } = useParams() as { accountId: string };
  const { loading, value } = useAsync(async () => {
    return await fluidily.accounts.get(accountId);
  }, [accountId]);
  if (loading) return null;
  if (!value) return <NotFound />;
  const left = nav(basePath);
  const accountPath = `${basePath}/${accountId}`;
  left.push({ text: "Overview", to: accountPath });
  left.push({ text: "Update", to: `${accountPath}/update` });
  left.push({ text: "Add Api Key", to: `${accountPath}/add-api-key` });

  return (
    <Routes>
      <Route path="" element={<AccountHome account={value} left={left} />} />
      <Route
        path="/update"
        element={<AddAccount account={value} left={left} />}
      />
      <Route
        path="/add-api-key"
        element={<AddAccountApiKey account={value} left={left} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AccountHome = ({
  account,
  left,
}: {
  account: Account;
  left: Crumb[];
}) => {
  const { fluidily } = useStores();
  const { loading, value } = useAsync(async () => {
    return await fluidily.accounts.apiKeys(account.id);
  }, [account.id]);

  const deleteApiKey = async (apiKey: ApiKey) => {};

  const columns = React.useMemo(
    () => [
      {
        key: "name",
        name: "Name",
      },
      {
        key: "demo",
        name: "Demo",
        renderCell: booleanFormatter("demo"),
      },
      deleteEntry(deleteApiKey, (row: ApiKey) => `Delete api key ${row.name}`),
    ],
    [],
  );

  if (loading) return null;
  const api = new StaticData<ApiKey>(value || []);
  const Dl = DLFactory({ sm: 6 });
  return (
    <OrgView left={left} title={`accounts - ${account.exchange}`}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" paragraph align="center">
              Account
            </Typography>
            <Dl entry="Internal ID">{account.id}</Dl>
            <Dl entry="Exchange">
              <Link to={`/data/exchanges/${account.exchange}`}>
                {account.exchange}
              </Link>
            </Dl>
            <Dl entry="Strategy">{account.strategy}</Dl>
            <Dl entry="Exchange account name">
              {account.exchange_account_name}
            </Dl>
            <Dl entry="Exchange account ID">{account.exchange_account_id}</Dl>
            <Dl entry="Demo">{<BooleanIcon value={account.demo} />}</Dl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" paragraph align="center">
              Api Keys
            </Typography>
            <ApiDataGrid api={api} columns={columns} />
          </Grid>
        </Grid>
      </Container>
    </OrgView>
  );
};

export default AccountRoutes;
