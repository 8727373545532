import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React from "react";
import Box from "./Box";

interface PanelProps {
  variant?: any;
  title?: React.ReactNode;
  children?: React.ReactNode;
  action?: React.ReactNode;
  titleTypographyProps?: any;
  headerSx?: any;
  [key: string]: any;
}

export const Panel = (props: PanelProps) => {
  const {
    children,
    title,
    action,
    variant = "outlined",
    titleTypographyProps,
    headerSx,
    ...card
  } = props;
  return (
    <Box>
      <Card variant={variant} {...card}>
        {title || action ? (
          <CardHeader
            title={title}
            action={action}
            sx={headerSx}
            titleTypographyProps={titleTypographyProps}
          />
        ) : null}
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

export default Panel;
