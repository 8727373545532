import HttpComponent from "./httpComponent";
import { Alert } from "./interfaces";

class Alerts extends HttpComponent {
  async getList(): Promise<Alert[]> {
    const response = await this.cli.get(`${this.cli.apiUrl}/alerts`);
    return response.data as Alert[];
  }

  async dismiss(id: string): Promise<void> {
    const url = `${this.cli.apiUrl}/alerts?id=${id}`;
    await this.cli.delete(url);
  }

  async dismissAll(): Promise<void> {
    await this.cli.delete(`${this.cli.apiUrl}/alerts`);
  }
}

export default Alerts;
