import Box from "@mui/material/Box";
import { format } from "d3-format";
import React from "react";

const formatNumber = format(",");

const numberFormatter = (field: string) => {
  return ({ row }: { row: any }) => {
    const value = formatNumber(row[field]);
    return <Box sx={{ textAlign: "right" }}>{value}</Box>;
  };
};

export default numberFormatter;
