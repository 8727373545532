import { useStores } from "@fluidily/stores";
import { CrudForm } from "@metablock/react";
import React from "react";
import OrgView from "../Views/OrgView";
import { defaultConfig } from "./Default";

const AddDashboard = (props: any) => {
  const { fluidily } = useStores();
  const schemaLoader = fluidily.schemaLoader("DashboardCreate");

  const getSchema = async () => {
    return await schemaLoader();
  };

  const submit = async (data: any) => {
    if (Object.keys(data).length > 0) {
      data.config = defaultConfig();
      await fluidily.dashboards.create(data);
    }
  };

  const label = "create new dashboard";

  return (
    <OrgView title="dashboards" {...props}>
      <CrudForm schema={getSchema} submit={submit} label={label} />
    </OrgView>
  );
};

export default AddDashboard;
