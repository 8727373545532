import Page from "@fluidily/Views/Page";
import Securities from "@fluidily/Views/Securities";
import React from "react";

const SecuritiesHome = () => {
  const filters = {
    tradable: "yes",
  };

  return (
    <Page title={"Crypto Securities"}>
      <Securities
        filters={filters}
        instrumentUrl="/data/securities"
        exchangeUrl="/data/exchanges"
        useUrl={true}
      />
    </Page>
  );
};

export default SecuritiesHome;
