import { EditIcon, PublishIcon } from "@fluidily/Components/Icons";
import Panel from "@fluidily/Components/Panel";
import { TradableBettingEvent, useStores } from "@fluidily/stores";
import { Link } from "@metablock/react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useAsync } from "react-use";

import React from "react";
import { Dl, panelProps } from "./Utils";

const EventService = ({
  tradableEvent,
  basePath,
}: {
  tradableEvent: TradableBettingEvent;
  basePath: string;
}) => {
  const { fluidily } = useStores();
  const [editing, setEdit] = React.useState(false);
  const changes = React.useRef<any>(undefined);
  const action = editing ? "submit" : "edit";

  const edit = (event: any) => {
    changes.current = { service_uid: event.target.value };
  };

  const { loading, value } = useAsync(async () => {
    if (!editing && changes.current) {
      const data = changes.current;
      changes.current = undefined;
      return await fluidily.betting_events.update_tradable(
        tradableEvent.id,
        data,
      );
    }
    return tradableEvent;
  }, [editing]);
  if (!loading && !value) return null;
  if (value) tradableEvent = value as TradableBettingEvent;

  return (
    <Panel
      title="Service"
      action={
        <Tooltip title={action} placement="top-end">
          <IconButton aria-label={action} onClick={() => setEdit(!editing)}>
            {editing ? <PublishIcon /> : <EditIcon />}
          </IconButton>
        </Tooltip>
      }
      {...panelProps()}
    >
      <Dl entry="name">
        <Link
          to={`${basePath}?service_name=${tradableEvent.service.name}`}
          underline="none"
        >
          {tradableEvent.service.name}
        </Link>
      </Dl>
      <Dl entry="UID" edit={editing ? edit : null}>
        {tradableEvent.service.uid}
      </Dl>
      <Dl entry="region">
        <Link
          to={`${basePath}?region=${tradableEvent.service.region}`}
          underline="none"
        >
          {tradableEvent.service.region}
        </Link>
      </Dl>
    </Panel>
  );
};

export default EventService;
