import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import DashboardPanels from "@fluidily/Dashboards";

export const SelectDashboardPanel = ({
  onChange,
  defaultValue,
}: {
  onChange: (name: string) => void;
  defaultValue?: string;
}) => {
  const [value, setValue] = React.useState(defaultValue || "");

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setValue(value);
    onChange(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>dashboard panel</InputLabel>
      <Select value={value} label="Panel" onChange={handleChange}>
        {Object.keys(DashboardPanels).map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const DashboardPanel = ({ panel }: { panel?: string }) => {
  const Panel = panel ? DashboardPanels[panel] : null;
  if (!Panel) return null;
  return <Panel />;
};

export default DashboardPanels;
