import { format } from "d3-format";

const currencyFormatters = new Map<string, any>();
export const formatNumber = format(",");

const usd = format("$,.1f");
const f1 = format(",.1f");
const f6 = format(",.6f");

export const round = (digits: number) => format(`,.${digits}f`);

currencyFormatters.set("usd", usd);
currencyFormatters.set("usdt", usd);
currencyFormatters.set("eur", (amount: number) => `€${f1(amount)}`);
currencyFormatters.set("gbp", (amount: number) => `£${f1(amount)}`);
currencyFormatters.set("btc", (amount: number) => `฿${f6(amount)}`);

export const formatCcy = (amount: number, currency?: string): string => {
  const formatter = currencyFormatters.get(currency || "usd") || formatNumber;
  return formatter(amount);
};
