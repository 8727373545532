import { NotFound } from "@metablock/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AddDashboard from "./AddDashboard";
import Dashboard from "./Dashboard";
import Dashboards from "./Dashboards";
import DefaultDashboard from "./Default";
import nav from "./nav";

const DashboardsRoutes = ({ basePath }: { basePath: string }) => {
  const left = nav(basePath);
  return (
    <Routes>
      <Route
        path="/list"
        element={<Dashboards basePath={basePath} left={left} />}
      />
      <Route path="/new" element={<AddDashboard left={left} />} />
      <Route path="" element={<DefaultDashboard basePath={basePath} />} />
      <Route
        path="/:dashboardName"
        element={<Dashboard basePath={basePath} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default DashboardsRoutes;
