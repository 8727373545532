import { ListItemLink } from "@metablock/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";
import { NavEntry } from "./interfaces";

const drawerWidth = 240;

export interface MetaDrawerProps extends DrawerProps {
  listItems: Array<Array<NavEntry>>;
  left?: React.ReactNode;
  rightLinks?: React.ReactNode;
  open?: boolean;
}

const ContentDrawerClosed = styled("main")(({ theme }: any) => ({
  flexGrow: 1,
  height: "100vh",
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: -drawerWidth,
}));

const ContentDrawerOpen = styled("main")(({ theme }: any) => ({
  flexGrow: 1,
  height: "100vh",
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 0,
}));

const DrawerHeader = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const PersistentDrawerLeft = (props: MetaDrawerProps) => {
  const { listItems, rightLinks, left } = props;
  const [open, setOpen] = React.useState(props.open || false);
  const theme = useTheme();
  let sxAppBar: Record<string, any> = {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  if (open)
    sxAppBar = {
      ...sxAppBar,
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    };

  const Content = open ? ContentDrawerOpen : ContentDrawerClosed;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <AppBar position="fixed" sx={sxAppBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            size="large"
            sx={{
              marginRight: theme.spacing(2),
              display: open ? "none" : null,
            }}
          >
            <MenuIcon />
          </IconButton>
          {left}
          {rightLinks}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {listItems.map((group, index) => (
          <React.Fragment key={`group-${index}`}>
            <Divider />
            <List>
              {group.map((entry: NavEntry) => (
                <ListItemLink button key={entry.name} to={entry.to}>
                  <ListItemIcon>{entry.icon}</ListItemIcon>
                  <ListItemText primary={entry.name} />
                </ListItemLink>
              ))}
            </List>
          </React.Fragment>
        ))}
      </Drawer>
      <Content>
        <DrawerHeader />
        {props.children}
      </Content>
    </Box>
  );
};

export default PersistentDrawerLeft;
