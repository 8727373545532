import { useStores } from "@fluidily/stores";
import { NotFound } from "@metablock/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Leagues from "./Leagues";
import Services from "./Services";
import Tasks from "./Tasks";

const AdmninIndex = (props: any) => {
  const { accountStore } = useStores();
  const ops = accountStore.opsUser();
  if (!ops) return <NotFound />;
  return (
    <Routes>
      <Route path="/services/*" element={<Services />} />
      <Route path="/tasks/*" element={<Tasks ops={ops} />} />
      <Route path="/leagues/*" element={<Leagues ops={ops} />} />
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default AdmninIndex;
