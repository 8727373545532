export const INSTRUMENT_TYPES = ["", "SPOT", "FUTURE", "PERPETUAL", "OPTION"];
export const EXCHANGES = [
  "",
  "bitmex",
  "binance",
  "coinbase",
  "deribit",
  "okx",
];

export const STRATEGIES = ["", "mmaker", "momentum"];
export const TRADING_STATES = [
  "",
  "UNSPECIFIED",
  "INACTIVE",
  "ACTIVE",
  "TRADING",
];
export const SERVICES = ["", "galileo", "enzo"];
export const REGIONS = ["", "eu-west-1"];
export const LEDGER_GROUPS = ["", "fee", "trade"];
export const BETTING_EVENT_TYPES = [
  "",
  "FOOTBALL",
  "GOLF",
  "HORSE_RACING",
  "TENNIS",
];
export const BETTING_STATES = [
  "NEW",
  "UPCOMING",
  "LIVE",
  "ENDED",
  "CANCELLED",
  "SETTLED",
];
export const asOptions = (values: string[]) =>
  values
    .filter((value: string) => value.length > 0)
    .map((value: string) => ({ value, label: value }));
