import { TradableBettingEvent } from "./betting";
import { StrategyMarket } from "./market";
import { Service } from "./service";
import { Timestamp } from "./timestamp";

export interface TradableMarket {
  TradableBettingEvent?: TradableBettingEvent;
  StrategyMarket?: StrategyMarket;
}

export class Snapshot {
  json: Record<string, any>;
  service: Service;
  tradable_market?: TradableMarket;
  timestamp: Timestamp;

  constructor(data: any) {
    this.json = data.json || {};
    this.service = data.service;
    this.timestamp = data.timestamp;
    this.tradable_market = data.tradable_market;
  }

  static fromMessage(msg: any): Snapshot {
    return new Snapshot(msg);
  }

  get tradable_betting_event(): TradableBettingEvent | undefined {
    return this.tradable_market?.TradableBettingEvent;
  }

  get strategy_market(): StrategyMarket | undefined {
    return this.tradable_market?.StrategyMarket;
  }

  get uid(): string {
    return tradable_market_uid(this.tradable_market);
  }
}

export const tradable_betting_event_uid = (
  event: TradableBettingEvent,
): string => `tradable_betting_event:${event.id}`;

export const strategy_market_uid = (event: StrategyMarket): string =>
  `strategy_market:${event.id}`;

export const tradable_market_uid = (
  tradable_market?: TradableMarket,
): string => {
  if (tradable_market?.TradableBettingEvent) {
    return tradable_betting_event_uid(tradable_market.TradableBettingEvent);
  } else if (tradable_market?.StrategyMarket) {
    return strategy_market_uid(tradable_market.StrategyMarket);
  } else {
    return "";
  }
};
