import { Security, securityFullName, useStores } from "@fluidily/stores";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import debounce from "lodash.debounce";
import React from "react";
import { useNavigate } from "react-router-dom";

interface SearchProps {
  size?: "small" | "medium";
  sx?: any;
  label?: string;
  onChange?: (event: any, newValue: any) => void;
  limit?: number;
  query?: Record<string, any>;
}

const SearchSecurity = (props: SearchProps) => {
  const navigate = useNavigate();
  const [options, setOptions] = React.useState<any[]>([]);
  const { fluidily } = useStores();
  const defaultOnChange = (event: any, newValue: any) => {
    if (newValue) {
      navigate(`/ops/instruments/${newValue.code}`);
    }
  };
  const {
    onChange = defaultOnChange,
    size = "medium",
    sx = { width: 300, padding: 1 },
    limit = 5,
    label = "search securities",
    query = { tradable: true },
  } = props;
  const api = fluidily.securities.loader({ limit, ...query });

  const doSearch = debounce(async (text) => {
    if (api.isDataLoading()) {
      doSearch(text);
      return;
    }
    api.setSearch(text);
    await api.loadData();
    setOptions(
      api.data.map((security: Security) => {
        const code = securityFullName(security.security_key);
        return { code, label: code };
      }),
    );
  }, 50);

  const search = (event: any) => {
    doSearch(event.currentTarget.value);
  };

  return (
    <Box sx={sx}>
      <Autocomplete
        freeSolo
        onChange={onChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size={size}
            variant="outlined"
            onChange={search}
            label={label}
          />
        )}
      />
    </Box>
  );
};

export default SearchSecurity;
