import { useStores } from "@fluidily/stores";
import { useAsync } from "react-use";

const useCountry = () => {
  const { fluidily } = useStores();
  const { loading, value } = useAsync(async () => {
    return await fluidily.countries.countryMap();
  });
  if (loading) return null;
  return value;
};

export default useCountry;
