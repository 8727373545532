import { formatCcy, formatNumber } from "@fluidily/utils/money";
import Box from "@mui/material/Box";
import { lightGreen, red } from "@mui/material/colors";
import React from "react";

export const Money = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  return (
    <Box component="div" sx={{ textAlign: "right" }}>
      {formatCcy(amount, currency)}
    </Box>
  );
};

export const ColorMoney = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  const color = amount < 0 ? red[900] : lightGreen[500];
  return (
    <Box component="span" sx={{ color, fontWeight: 900 }}>
      {formatCcy(Math.abs(amount), currency)}
    </Box>
  );
};

export const Number = ({ amount }: { amount: number }) => {
  return (
    <Box component="div" sx={{ textAlign: "right" }}>
      {formatNumber(amount)}
    </Box>
  );
};
