import { DataGridHeaderFilter } from "@metablock/react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React from "react";
import { RenderHeaderCellProps } from "react-data-grid";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface HeaderFilter<R> extends RenderHeaderCellProps<R> {
  sx?: Record<string, any>;
  dataGridFilters: any;
  options: string[];
  emptyOption?: string;
}

export const DataGridMultiSelectFilter = <R,>({
  dataGridFilters,
  column,
  options,
  emptyOption,
  ...props
}: HeaderFilter<R>) => {
  let selected = dataGridFilters.filters[column.key] || [];
  if (selected && !Array.isArray(selected)) {
    selected = [selected];
  }
  const handleChange = (event: any) => {
    dataGridFilters.set(column.key, event.target.value);
  };
  return (
    // @ts-ignore
    <DataGridHeaderFilter column={column} {...props}>
      {() => (
        <Select
          multiple
          fullWidth
          size="small"
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box>
              {selected.map((value: string) => (
                <Chip
                  key={value}
                  label={value}
                  size="small"
                  sx={{ fontSize: "60%" }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((value: string, index: number) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      )}
    </DataGridHeaderFilter>
  );
};
