import { Crumb } from "@metablock/react";

const nav = (basePath: string): Crumb[] => {
  return [
    { text: "Add Dashboard", to: `${basePath}/new` },
    { text: "Dashboards", to: `${basePath}/list` },
  ];
};

export default nav;
