import { useStores } from "@fluidily/stores";
import { CrudForm } from "@metablock/react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React from "react";
import UserDefaultOrgs from "./Views/UserDefaultOrgs";

const UpdateProfile = () => {
  const { metablock, userStore } = useStores();
  const schemaLoader = metablock.schemaLoader("UserUpdate");

  const getSchema = async () => {
    return await schemaLoader();
  };

  const submit = async (data: any) => {
    if (Object.keys(data).length > 0) {
      await userStore.updateUser(data);
    }
  };

  return (
    <>
      <CrudForm
        defaults={userStore.current as any}
        schema={getSchema}
        submit={submit}
        label="Update Profile"
      />
      <Box mt={4}>
        <Container maxWidth={false}>
          <UserDefaultOrgs />
        </Container>
      </Box>
    </>
  );
};

export default UpdateProfile;
