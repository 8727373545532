import "@fluidily/elements";
import { getBlock } from "@metablock/core";
import { Page, useWindowSize } from "@metablock/react";
import { Box, Container, Typography } from "@mui/material";
import { color } from "d3-color";
import React from "react";
import Header from "./Headers";

const Landing = () => {
  const block = getBlock();
  const size = useWindowSize();
  const c: any = color("#eee");
  c.opacity = 0.5;
  const titleSx = {
    fontWeight: 700,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  };
  const full = {
    position: "absolute",
    top: 0,
    bottom: 0,
  };
  return (
    <Page title="Fluidily">
      <Box sx={full}>
        <connected-particles
          width={size.width}
          height={size.height}
          color={c}
        ></connected-particles>
      </Box>
      <Header />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Container maxWidth="md" sx={{ marginTop: "20%" }}>
          <Typography
            variant="h2"
            component="h1"
            align="center"
            paragraph
            sx={titleSx}
          >
            {block.title}
          </Typography>
          <Typography variant="h4" component="h3" align="center">
            {block.description}
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

export default Landing;
