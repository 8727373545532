import { DataApi, UserOrg } from "@metablock/core";
import { action, makeObservable, observable } from "mobx";
import BaseStore from "./base";
import { Account, Entity, Fluidily, Ledger, Order } from "./client";
import OpsUser from "./opsUser";

class AccountStore extends BaseStore {
  orgsByName: Record<string, UserOrg> = {};
  org?: UserOrg = undefined;
  entity?: Entity = undefined;

  constructor(fluidily: Fluidily) {
    super(fluidily);
    makeObservable(this, {
      getEntity: action,
      getBankingAuthLink: action,
      setOrg: action,
      setEntity: action,
      entity: observable,
      org: observable,
    });
  }

  get opsOrg() {
    return this.orgsByName.fluidily;
  }

  opsUser() {
    if (!this.opsOrg) return null;
    return new OpsUser(this.fluidily, this.opsOrg);
  }

  setOrg(org?: UserOrg) {
    this.entity = undefined;
    this.org = org;
  }

  setEntity(entity: Entity) {
    this.entity = entity;
  }

  getOrg(): UserOrg {
    if (!this.org) throw new Error("org not set in account store");
    return this.org as UserOrg;
  }

  accountsLoader(query?: any) {
    return this.fluidily.accounts.loader(query);
  }

  loader(query?: Record<string, any>): DataApi<Account> {
    if (!this.entity)
      throw new Error("entity not set, cannot get account loader");
    return this.fluidily.loader(
      `${this.fluidily.apiUrl}/entities/${this.entity.id}/accounts`,
      query,
    );
  }

  ledgerLoader(query?: Record<string, any>): DataApi<Ledger> {
    if (!this.entity)
      throw new Error("entity not set, cannot get account loader");
    return this.fluidily.loader(
      `${this.fluidily.apiUrl}/entities/${this.entity.id}/ledgers`,
      query,
    );
  }

  orderLoader(query?: Record<string, any>): DataApi<Order> {
    return this.fluidily.loader(`${this.fluidily.apiUrl}/orders`, query);
  }

  async getEntity(id: string) {
    return await this.fluidily.entities.get(id);
  }

  async getOrder(id: string): Promise<Order> {
    const response = await this.fluidily.get(
      `${this.fluidily.apiUrl}/orders/${id}`,
    );
    return response.data as Order;
  }

  async createAccount(data: Record<string, any>) {
    const entity = this.currentEntity();
    return await this.fluidily.entities.createAccount(entity, data);
  }

  async getBankingAuthLink(query: any) {
    return await this.fluidily.accounts.getBankingAuthLink(query);
  }

  currentEntity(): Entity {
    if (!this.entity) throw new Error("entity not set in account store");
    return this.entity as Entity;
  }
}

export default AccountStore;
