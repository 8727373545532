export class Value {
  mid: number;
  delta: number;
  direction: number;

  constructor(mid: number) {
    this.mid = mid;
    this.delta = 0;
    this.direction = 0;
  }

  update(mid: number): Value {
    const ticker = new Value(mid);
    ticker.delta = mid - this.mid;
    ticker.direction = ticker.delta == 0 ? this.direction : ticker.delta;
    return ticker;
  }
}

class History {
  history: any[];
  private callbacks: any[];

  constructor() {
    this.history = [];
    this.callbacks = [];
  }

  get last(): Value {
    const i = this.history.length;
    return i === 0 ? new Value(0) : this.history[i - 1];
  }

  insert_update(mid: number) {
    const i = this.history.length;
    const value = i === 0 ? new Value(mid) : this.history[i - 1].update(mid);
    this.history.push(value);
    if (this.history.length > 100) this.history.shift();
    this.callbacks.forEach((callback: any) => callback(value));
  }

  addCallback(callback: any) {
    this.callbacks.push(callback);
  }

  removeCallback(callback: any) {
    const i = this.callbacks.indexOf(callback);
    if (i > -1) this.callbacks.splice(i, 1);
  }
}

export class Instrument extends History {
  ticker: any = {};

  update(ticker: any) {
    this.ticker = ticker;
    this.insert_update(ticker.mid);
  }
}

export class Market extends History {
  code: string;
  tickers: Map<string, Instrument>;

  constructor(code: string) {
    super();
    this.code = code;
    this.tickers = new Map();
  }

  getInstrument(exchange: string): Instrument {
    if (!this.tickers.has(exchange))
      this.tickers.set(exchange, new Instrument());
    return this.tickers.get(exchange) as Instrument;
  }

  update(market: any) {
    const tickers = market.tickers || {};
    Object.keys(tickers).forEach((exchange: string) => {
      const instrument = this.getInstrument(exchange);
      instrument.update(tickers[exchange]);
    });
    this.insert_update(market.mid);
  }
}

const markets = new Map<string, Market>();

export const getMarket = (code: string): Market => {
  if (!markets.has(code)) markets.set(code, new Market(code));
  return markets.get(code) as Market;
};
