import { blue, grey } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const options = {
  drawerWidth: 240,
};
export const fontFamilyMono = "'Roboto Mono', monospace";
export const gridFullHeight = () => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: 2,
});

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }

  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        height: "100%",
      },
      html: {
        height: "100%",
      },
      "#__metablock": {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
};

export const lightTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: {
        light: blue["700"],
        main: blue["800"],
        dark: blue["900"],
      },
      neutral: {
        main: grey["900"],
        contrastText: grey["50"],
      },
    },
  }),
);

export const darkTheme = responsiveFontSizes(
  createTheme({
    components,
    palette: {
      mode: "dark",
      primary: {
        light: blue["300"],
        main: blue["500"],
        dark: blue["700"],
      },
      secondary: {
        light: grey["700"],
        main: grey["800"],
        dark: grey["900"],
      },
      neutral: {
        main: grey["50"],
        contrastText: grey["900"],
      },
    },
  }),
);

export const getCodeBackground = (mode: string) => {
  return mode === "light" ? "#fff" : "#171717";
};
