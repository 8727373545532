import { useStores } from "@fluidily/stores";
import { Message } from "@metablock/store";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import React from "react";

const Messages = () => {
  const { messageStore } = useStores();
  const message = messageStore.message;

  if (!message) return null;
  const anchorOrigin: any = { vertical: "top", horizontal: "center" };
  const msg = message as Message;

  const handleClose = (event: any) => {
    messageStore.clear();
  };

  return (
    <Snackbar
      open
      anchorOrigin={anchorOrigin}
      autoHideDuration={msg.duration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        elevation={1}
        severity={msg.level as any}
        variant="filled"
      >
        <Typography align="center">{msg.text}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default observer(Messages);
