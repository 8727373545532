import Page from "@fluidily/Views/Page";
import React from "react";
import { useParams } from "react-router-dom";

const OrgView = (props: any) => {
  const { title, ...extra } = props;
  const { orgName } = useParams() as { orgName: string };
  const basePath = `orgs/${orgName}`;
  const tabs = React.useMemo(
    () => [
      { text: "dashboards", to: `/${basePath}/dashboards` },
      { text: "markets", to: `/${basePath}/markets` },
      { text: "betting", to: `/${basePath}/betting` },
      { text: "accounts", to: `/${basePath}/accounts` },
    ],
    [basePath],
  );
  const orgTitle = title ? `${orgName} - ${title}` : orgName;

  return <Page tabs={tabs} title={orgTitle} {...extra} />;
};

export default OrgView;
