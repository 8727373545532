import { SecurityKey } from "./security";

export class Feed {
  public securityKey: SecurityKey;

  constructor(securityKey: SecurityKey) {
    this.securityKey = securityKey;
  }
}

export class BookEntry {
  public price: number;
  public amount: number;

  constructor(price: number, amount: number) {
    this.price = price;
    this.amount = amount;
  }

  static fromMessage(msg: any): BookEntry {
    return new BookEntry(+msg.price, +msg.amount);
  }
}

export class OrderBookTop extends Feed {
  public bid: BookEntry;
  public ask: BookEntry;

  constructor(securityKey: SecurityKey, bid: BookEntry, ask: BookEntry) {
    super(securityKey);
    this.bid = bid;
    this.ask = ask;
  }

  static fromMessage(msg: any): OrderBookTop {
    return new OrderBookTop(
      SecurityKey.fromMessage(msg.security_key),
      BookEntry.fromMessage(msg.bid),
      BookEntry.fromMessage(msg.ask),
    );
  }

  get mid(): number {
    return (this.bid.price + this.ask.price) / 2;
  }
}
