import { Exchange, useStores } from "@fluidily/stores";
import { NotFound } from "@metablock/react";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import ExchangeView from "./Exchange";
import Exchanges from "./Exchanges";
import UpdateExchange from "./UpdateExchange";

const ExchangesHome = (props: any) => {
  return (
    <Routes>
      <Route path="" element={<Exchanges />} />
      <Route path="/:exchangeName/*" element={<ExchangeHome />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const ExchangeHome = () => {
  const { fluidily } = useStores();
  const { exchangeName } = useParams() as { exchangeName: string };
  const { loading, value } = useAsync(async () => {
    return await fluidily.exchanges.get(exchangeName);
  }, [exchangeName]);
  if (loading) return null;
  if (!value) return <NotFound />;
  const exchange = value as Exchange;
  const tabs = [
    { text: "View", to: `/data/exchanges/${exchangeName}` },
    { text: "Edit", to: `/data/exchanges/${exchangeName}/update` },
  ];
  return (
    <Routes>
      <Route
        path=""
        element={<ExchangeView exchange={exchange} tabs={tabs} />}
      />
      <Route
        path="/update"
        element={<UpdateExchange exchange={exchange} tabs={tabs} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ExchangesHome;
