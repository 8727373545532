import { SchemaEntry } from "@metablock/react";

const createSchema = (
  schema: SchemaEntry,
  spec: Record<string, any>,
): SchemaEntry => {
  const { ...newSchema } = schema;
  if (newSchema.properties) {
    const { properties } = newSchema;
    const newProperties: Record<string, any> = {};
    Object.keys(properties).forEach((key) => {
      const property = properties[key];
      if (property.$ref) {
        const ref = property.$ref.replace("#/components/schemas/", "");
        const refSchema = spec.components.schemas[ref];
        if (refSchema) {
          newProperties[key] = createSchema(refSchema, spec);
        }
      } else if (property.items && property.items.$ref) {
        const ref = property.items.$ref.replace("#/components/schemas/", "");
        const refSchema = spec.components.schemas[ref];
        if (refSchema) {
          newProperties[key] = {
            ...property,
            items: createSchema(refSchema, spec),
          };
        }
      } else {
        newProperties[key] = property;
      }
    });
    newSchema.properties = newProperties;
  }
  return newSchema;
};

export default createSchema;
