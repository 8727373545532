import { getBlock } from "@metablock/core";
import { Header as BaseHeader, Link } from "@metablock/react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import React from "react";
import { sxRight } from "../Components/RightBox";

const Header = (props: any) => {
  const BrandComponent = () => {
    return (
      <Link to="/">
        <Typography variant="h5"></Typography>
      </Link>
    );
  };

  return (
    <BaseHeader
      backgroundColor="transparent"
      BrandComponent={BrandComponent}
      RightLinks={HeaderLinks}
      paddingTop={1}
    />
  );
};

export default Header;

const HeaderLinks = (props: any) => {
  const block = getBlock();

  return (
    <List sx={sxRight()}>
      <ListItem>
        <Link to={block.plugins.account.login_url}>
          <Button variant="outlined" color="secondary">
            Login
          </Button>
        </Link>
      </ListItem>
    </List>
  );
};
