import { getTab } from "@fluidily/utils/breadcrumbs";
import { Buttons, Crumb, Page as MetablockPage } from "@metablock/react";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";

export interface PageProps {
  title?: string;
  pageTitle?: string;
  description?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  crumbs?: Crumb[];
  tabs?: Crumb[];
  left?: Crumb[];
  pages?: Crumb[];
  [x: string]: any;
}

const Page = (props: PageProps) => {
  const location = useLocation();
  const { pathname } = location;
  const {
    title = "",
    pageTitle,
    children,
    tabs = [],
    left = [],
    maxWidth = false,
    description = "",
  } = props;
  const leftIndex = getTab(left, pathname);
  const tabIndex = getTab(tabs, pathname);
  return (
    <MetablockPage title={title} description={description}>
      <Container maxWidth={false}>
        <Box pt={1} pb={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Buttons
                tabs={left}
                path={leftIndex}
                size="small"
                color="primary"
              />
            </Grid>
            <Grid item>
              <Buttons
                tabs={tabs}
                path={tabIndex}
                size="small"
                color="primary"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container
        maxWidth={maxWidth}
        sx={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        {title ? (
          <Typography component="h1" variant="h5" align="center" pt={2} pb={2}>
            {pageTitle || title}
          </Typography>
        ) : null}
        {children}
      </Container>
    </MetablockPage>
  );
};

export default Page;
