import { useStores } from "@fluidily/stores";
import { useEffect, useRef } from "react";
import { ChannelId, WebsocketHandler } from "../stores";

const useWebsocket = (handler: WebsocketHandler, channelId: ChannelId) => {
  const { streamingStore } = useStores();
  const target = useRef<WebsocketHandler>(handler);

  useEffect(() => {
    target.current = handler;
    streamingStore.subscribe(handler, channelId);
    return () => {
      streamingStore.unsubscribe(handler, channelId);
    };
  }, [channelId.key]);
};

export default useWebsocket;
