import { SchemaRegistry } from "@metablock/react";
import ListAutocompleteSchema from "./ListAutocomplete";
import MarketParams from "./MarketParams";
import NumberSchema from "./Number";
import SecurityKeysInput from "./SecurityKeys";
export { default as onError422 } from "./error422";

// Override metablock form schema
SchemaRegistry.set("integer", NumberSchema);
SchemaRegistry.set("number", NumberSchema);
SchemaRegistry.set("array:autocomplete", ListAutocompleteSchema);
SchemaRegistry.set("array:security_keys", SecurityKeysInput);
SchemaRegistry.set("object:params", MarketParams);
