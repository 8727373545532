import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import React, { MouseEvent } from "react";
import { MenuIcon } from "./Icons";

const MenuEntry = (props: any) => {
  const {
    title = "menu",
    id = "menu-id",
    Icon = MenuIcon,
    Items,
    size = "medium",
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={title}>
        <IconButton
          aria-controls={open ? id : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleMenuOpen}
          color="inherit"
          size={size}
        >
          <Icon />
        </IconButton>
      </Tooltip>
      <Menu
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <Items handleClose={handleClose} />
      </Menu>
    </>
  );
};

export default MenuEntry;
