import debounce from "lodash.debounce";

class Resize {
  private handleResize: any;

  constructor(resize: () => void, delay?: number) {
    this.handleResize = debounce(resize, delay || 200);
    window.addEventListener("resize", this.handleResize);
  }

  destroy() {
    window.removeEventListener("resize", this.handleResize);
  }
}

export default Resize;
