import { EmailIcon, IdIcon } from "@fluidily/Icons";
import { useStores } from "@fluidily/stores";
import { User } from "@metablock/core";
import { Link } from "@metablock/react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import React from "react";

const Details = () => {
  const { userStore, accountStore } = useStores();
  const user: User = userStore.current as User;
  const orgs = accountStore.orgsByName;
  const name = user.first_name
    ? user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.first_name
    : user.username;
  return (
    <Box>
      <Typography variant="h5" paragraph>
        {name}
      </Typography>
      <List>
        <ListItem disablePadding>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary={user.email} />
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <IdIcon />
          </ListItemIcon>
          <ListItemText secondary={user.id} />
        </ListItem>
      </List>
      <Divider sx={{ p: 1 }} />
      <Typography variant="h6">Organizations</Typography>
      <List>
        {Object.values(orgs).map((org: any) => (
          <ListItem disablePadding key={org.org_id}>
            <ListItemText
              primary={<Link to={`/orgs/${org.org_name}`}>{org.org_name}</Link>}
              secondary={org.roles}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Details;
