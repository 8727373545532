import DefaultEntityIcon from "@mui/icons-material/Poll";
import DefaultStrategyIcon from "@mui/icons-material/TrendingUp";
import React from "react";
export {
  AccountCircle as AccountIcon,
  Settings as AdminIcon,
  Dashboard as DashboardIcon,
  Delete as DeleteIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  EditNote as EditIcon,
  Publish as PublishIcon,
} from "@mui/icons-material";

const EntityIcons: any = {
  gateway: {
    default: DefaultEntityIcon,
    icons: {},
  },
  strategy: {
    default: DefaultStrategyIcon,
    icons: {},
  },
};

const EntityIcon = ({ name, type }: { name: string; type: string }) => {
  const icons = EntityIcons[type] || { default: DefaultEntityIcon, icons: {} };
  const Icon = icons.icons[name] || icons.default;
  return <Icon />;
};

export default EntityIcon;
