import { getBlock } from "@metablock/core";
import { NotFound } from "@metablock/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Landing";
import Login from "./Login";

const Public = () => {
  const block = getBlock();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={block.plugins.account.login_url} element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Public;
