import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

const Fieldset = (props: any) => {
  const { description = "", name, children } = props;
  return (
    <Paper
      sx={{
        p: 1,
        mb: 1,
        //border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
        //bgColor: (theme: Theme) => theme.palette.grey[700],
      }}
    >
      <Typography variant="body2">
        <Chip label={name} color="primary" component="span"></Chip>{" "}
        {description}
      </Typography>
      {children}
    </Paper>
  );
};

export default Fieldset;
