import { Player, useStores } from "@fluidily/stores";
import { ApiDataGrid, useDataGridFilters } from "@metablock/react";
import Link from "@mui/material/Link";
import React from "react";
import { useAsync } from "react-use";

const TennisPlayers = (props: any) => {
  const { fluidily } = useStores();

  const { loading, value } = useAsync(async () => {
    return await fluidily.countries.countryMap();
  });

  if (loading) return null;
  return <Inner countryMap={value} {...props} />;
};

const Inner = (props: any) => {
  const { filters, columns, countryMap, useUrl = false, ...extra } = props;
  const { fluidily } = useStores();
  const dataGridFilters = useDataGridFilters(useUrl, {
    event_type: "TENNIS",
    ...filters,
  });

  // This is required to force re-render when filters prop change
  React.useEffect(() => {
    dataGridFilters.setSome(filters);
  }, [filters]);

  const api = fluidily.players.loader();
  const defaultCols = React.useMemo(
    () => [
      {
        key: "full_name",
        name: "Full Name",
        renderCell: ({ row }: { row: Player }) => {
          const url = row.extra.tennisexplorer_url;
          return url ? (
            <Link href={url} target="_blank" rel="noopener">
              {row.full_name}
            </Link>
          ) : (
            row.full_name
          );
        },
      },
      {
        key: "country",
        name: "Country",
        renderCell: ({ row }: { row: Player }) => {
          const country = countryMap[row.country];
          return country ? country.name : row.country;
        },
      },
      {
        key: "rank",
        name: "Ranking",
      },
    ],
    [dataGridFilters.filters],
  );

  const cols: any = React.useMemo(() => {
    return columns
      ? defaultCols.filter((col: any) => columns.indexOf(col.key) > -1)
      : defaultCols;
  }, [columns, defaultCols]);

  return (
    <ApiDataGrid
      api={api}
      columns={cols}
      headerRowHeight={80}
      className="rdg-dark"
      fullHeight={true}
      dataGridFilters={dataGridFilters}
      search
      {...extra}
    />
  );
};

export const ATPRanking = () => {
  return <TennisPlayers filters={{ sex: "MALE" }} />;
};

export default TennisPlayers;
