import rowDate from "@fluidily/Components/dateFormatter";
import { ExchangeId } from "@fluidily/Views/ExchangeSymbol";
import { LeagueUrl } from "@fluidily/Views/Urls";
import {
  Snapshot,
  TradableBettingEvent,
  bettingEventVolume,
  useStores,
} from "@fluidily/stores";
import { gridFullHeight } from "@fluidily/theme";
import { formatDateTime } from "@fluidily/utils/dates";
import { formatNumber } from "@fluidily/utils/money";
import {
  BETTING_EVENT_TYPES,
  REGIONS,
  STRATEGIES,
  TRADING_STATES,
} from "@fluidily/utils/options";
import {
  ApiDataGrid,
  DataGridNoFilter,
  DataGridSelectFilter,
  Link,
  useDataGridFilters,
} from "@metablock/react";
import Box from "@mui/material/Box";
import React from "react";
import OrgView from "../Views/OrgView";
import { fromSnapshot } from "./Snapshot";

const noFilter = (p: any) => <DataGridNoFilter {...p} />;

const TradableBettingEvents = ({ basePath }: { basePath: string }) => {
  const { fluidily } = useStores();
  const api = fluidily.betting_events.tradable_loader();
  const dataGridFilters = useDataGridFilters(true, {
    state: ["UPCOMING", "LIVE"],
  });

  const columns = React.useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        minWidth: 200,
        frozen: true,
        resizable: true,
        renderCell: ({ row }: { row: TradableBettingEvent }) => (
          <Link to={`${basePath}/${row.id}`}>
            {row.betting_event.full_name}
          </Link>
        ),
        renderHeaderCell: noFilter,
      },
      {
        key: "event_type",
        name: "Type",
        minWidth: 100,
        resizable: true,
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={BETTING_EVENT_TYPES}
            {...p}
          />
        ),
        renderCell: ({ row }: { row: TradableBettingEvent }) =>
          row.betting_event.event_type,
      },
      {
        key: "league",
        name: "Tournament",
        minWidth: 150,
        renderHeaderCell: noFilter,
        resizable: true,
        renderCell: ({ row }: { row: TradableBettingEvent }) => (
          <LeagueUrl league={row.betting_event.league_season} />
        ),
      },
      {
        key: "league_country",
        name: "Country",
        maxWidth: 80,
        renderHeaderCell: noFilter,
        resizable: true,
        renderCell: ({ row }: { row: TradableBettingEvent }) =>
          row.betting_event.league_season.country,
      },
      {
        key: "strategy",
        name: "strategy",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={STRATEGIES}
            {...p}
          />
        ),
      },
      {
        key: "trading_state",
        name: "Trading state",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={TRADING_STATES}
            {...p}
          />
        ),
      },
      {
        key: "state",
        name: "state",
        minWidth: 80,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
        renderCell: ({ row }: { row: TradableBettingEvent }) =>
          row.betting_event.state,
      },
      {
        key: "volume",
        name: "Volume",
        maxWidth: 100,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
        renderCell: ({ row }: { row: TradableBettingEvent }) => (
          <Box sx={{ textAlign: "right" }}>
            {formatNumber(Math.round(bettingEventVolume(row.betting_event)))}
          </Box>
        ),
      },
      {
        key: "is_ready",
        name: "ready",
        maxWidth: 80,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "service_uid",
        name: "Service UID",
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
        renderCell: fromSnapshot(
          (snapshot: Snapshot | null) => snapshot?.service?.uid,
        ),
      },
      {
        key: "inventory",
        name: "Inventory",
        resizable: true,
        renderHeaderCell: (p: any) => <DataGridNoFilter {...p} />,
      },
      {
        key: "exchange_id",
        name: "Exchange ID",
        resizable: true,
        renderHeaderCell: noFilter,
        renderCell: ({ row }: { row: TradableBettingEvent }) => (
          <ExchangeId betting_event={row.betting_event} />
        ),
      },
      {
        key: "start__gte",
        name: "Start",
        resizable: true,
        minWidth: 120,
        renderHeaderCell: noFilter,
        renderCell: rowDate(
          (row: TradableBettingEvent) => row.betting_event.start,
          formatDateTime,
        ),
      },
      {
        key: "region",
        name: "Region",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={REGIONS}
            {...p}
          />
        ),
        renderCell: ({ row }: { row: TradableBettingEvent }) =>
          row.service.region,
      },
    ],
    [dataGridFilters.filters],
  );

  return (
    <OrgView title="Tradable betting events">
      <ApiDataGrid
        sx={gridFullHeight()}
        columns={columns}
        api={api}
        rowHeight={40}
        headerRowHeight={80}
        style={{ flex: 1 }}
        dataGridFilters={dataGridFilters}
      />
    </OrgView>
  );
};

export default TradableBettingEvents;
