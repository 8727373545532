import HttpComponent from "./httpComponent";
import { League } from "./interfaces";

class Sport extends HttpComponent {
  async getLeague(id: string): Promise<League> {
    const url = `${this.cli.apiUrl}/leagues/${id}`;
    const response = await this.cli.get(url);
    return response.data as League;
  }

  async updateLeague(id: string, body: Record<string, any>): Promise<League> {
    const url = `${this.cli.apiUrl}/leagues/${id}`;
    const response = await this.cli.patch(url, { body });
    return response.data as League;
  }
}

export default Sport;
