import { useNotebook } from "@fluidily/hooks";
import { UserOrg } from "@metablock/core";
import React from "react";
import "react-data-grid/lib/styles.css";
import { useAsync } from "react-use";
import Private from "./Private";
import Public from "./Public";
import { useStores } from "./stores";

const Main = () => {
  useNotebook();
  const { commonStore, userStore, accountStore, metablock } = useStores();

  const { loading } = useAsync(async () => {
    const loader = metablock.user.orgsLoader();
    if (commonStore.token) {
      await userStore.getUser();
      if (userStore.current) await loader.loadData();
    }
    commonStore.setAppLoaded();
    accountStore.orgsByName = loader.data.reduce(
      (o: Record<string, UserOrg>, org: UserOrg) => {
        o[org.org_name] = org;
        return o;
      },
      {},
    );
  });

  if (loading) return null;
  if (userStore.current && is_active(userStore.current.status))
    return <Private />;
  else return <Public />;
};

const is_active = (status: string) => {
  return ["ACTIVE", "VERIFIED"].indexOf(status) > -1;
};

export default Main;
