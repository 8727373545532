import { DataApi } from "@metablock/core";
import { Player } from "../protos";
import HttpComponent from "./httpComponent";

class Players extends HttpComponent {
  loader(query?: any): DataApi<Player> {
    return this.cli.loader(`${this.cli.apiUrl}/players`, query);
  }
}

export default Players;
