import React from "react";
import { Route, Routes } from "react-router-dom";
import Service from "./Service";
import Services from "./Services";

const ServiceHome = () => {
  return (
    <Routes>
      <Route path="" element={<Services />} />
      <Route path=":serviceName" element={<Service />} />
    </Routes>
  );
};

export default ServiceHome;
