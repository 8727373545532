import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";

const DLFactory = ({ sm, pb }: { sm?: number; pb?: number }) => {
  const lsm = sm || 4;
  pb = pb || 1;

  return ({
    entry,
    children,
    edit,
  }: {
    entry: any;
    children: any;
    edit?: any;
  }) => {
    return (
      <Grid container spacing={0} sx={{ pb }}>
        <Grid item xs={12} sm={lsm}>
          <span>{entry}</span>
        </Grid>
        <Grid item xs={12} sm={12 - lsm}>
          {edit ? (
            <TextField
              fullWidth
              defaultValue={children}
              size="small"
              onChange={edit}
            />
          ) : (
            children
          )}
        </Grid>
      </Grid>
    );
  };
};

export default DLFactory;
