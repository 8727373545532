import { FormData } from "@metablock/react";

const error422 = (errors: any, form: FormData) => {
  const detail = errors?.data?.detail || [];
  const errorData = detail.map((d: any) => ({
    field: d.loc[d.loc.length - 1],
    message: d.msg,
  }));
  form.setErrors(errorData, true);
};

export default error422;
