import { SecurityKey } from "./security";

export enum TradingState {
  UNSPECIFIED = "UNSPECIFIED",
  INACYIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  TRADING = "TRADING",
}

export interface StrategyMarket {
  id: number;
  name: string;
  strategy: string;
  security_keys: SecurityKey[];
  signal_security_keys: SecurityKey[];
  trading_state: TradingState;
  timestamp: string;
  service_name: string;
  region: string;
}
