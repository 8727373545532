import { NotFound } from "@metablock/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { Route, Routes } from "react-router-dom";
import BettingEvents from "./BettingEvents";
import Exchanges from "./Exchanges";
import Securities from "./Securities";

const DataIndex = (props: any) => {
  return (
    <Routes>
      <Route path="/securities" element={<Securities />} />
      <Route path="/exchanges/*" element={<Exchanges />} />
      <Route path="/betting-events/*" element={<BettingEvents />} />
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default observer(DataIndex);
