import DLFactory from "@fluidily/Components/DescriptionList";
import { DeleteIcon } from "@fluidily/Components/Icons";
import SnapshotView from "@fluidily/Views/Snapshot";
import { useWebsocket } from "@fluidily/hooks";
import {
  ChannelId,
  Snapshot,
  StrategyMarket,
  useStores,
} from "@fluidily/stores";
import { formatTimestamp } from "@fluidily/utils/dates";
import { formatNumber } from "@fluidily/utils/money";
import { Crumb, Link, NotFound } from "@metablock/react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import OrgView from "../Views/OrgView";
import { AddEditMarket } from "./AddMarket";

const MarketHome = ({
  left,
  basePath,
}: {
  left: Crumb[];
  basePath: string;
}) => {
  const { fluidily } = useStores();
  const { marketId } = useParams() as { marketId: string };

  const { loading, value } = useAsync(async () => {
    return fluidily.markets.get(marketId);
  }, [marketId]);
  if (loading) return null;
  if (!value) return <NotFound />;

  const deleteMarket = async () => {
    await fluidily.markets.delete(marketId);
  };

  const nav = [
    ...left,
    { text: "view market", to: `${basePath}/${marketId}` },
    { text: "edit market", to: `${basePath}/${marketId}/edit` },
    {
      icon: <DeleteIcon />,
      color: "error",
      text: "delete market",
      onClick: deleteMarket,
    },
  ];
  const market = value as StrategyMarket;
  const title = `market - ${market.strategy} ${market.name}`;
  const Dl = DLFactory({ sm: 4 });
  return (
    <OrgView title={title} left={nav}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" paragraph align="center">
            Market details
          </Typography>
          <Dl entry="internal ID">{market.id}</Dl>
          <Dl entry="name">{market.name}</Dl>
          <Dl entry="strategy">
            <Link to={`${basePath}?strategy=${market.strategy}`}>
              {market.strategy}
            </Link>
          </Dl>
          <Dl entry="service">
            <Link to={`${basePath}?service_name=${market.service_name}`}>
              {market.service_name}
            </Link>
          </Dl>
          <Dl entry="region">
            <Link to={`${basePath}?region=${market.region}`}>
              {market.region}
            </Link>
          </Dl>
          <Dl entry="state">{market.trading_state}</Dl>
          <SnapshotInfo market={market} basePath={basePath} sx={{ mt: 4 }} />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Routes>
            <Route path="" element={<MarketView market={market} />} />
            <Route path="/edit" element={<AddEditMarket market={market} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Grid>
      </Grid>
    </OrgView>
  );
};

const SnapshotInfo = ({
  market,
  basePath,
  sx,
}: {
  market: StrategyMarket;
  basePath: string;
  sx?: any;
}) => {
  const channel = new ChannelId({ channel: "snapshot" });
  const [snapshot, setSnapshot] = React.useState<Snapshot | null>(null);
  useWebsocket((snapshot?: Snapshot) => {
    if (
      snapshot &&
      snapshot.strategy_market &&
      snapshot.strategy_market.id === market.id
    ) {
      setSnapshot(snapshot);
    }
  }, channel);
  if (!snapshot) return null;
  const Dl = DLFactory({ sm: 4 });
  return (
    <Box sx={sx}>
      <Typography variant="h6" paragraph align="center">
        Live snapshot info
      </Typography>
      <Dl entry="service">
        <Link to={`${basePath}?service_name=${market.service_name}`}>
          {snapshot.service.name}
        </Link>
      </Dl>
      <Dl entry="region">
        <Link to={`${basePath}?region=${snapshot.service.region}`}>
          {market.region}
        </Link>
      </Dl>
      <Dl entry="service UID">{snapshot.service.uid}</Dl>
      <Dl entry="state">{snapshot.json.strategy?.state}</Dl>
      <Dl entry="tick timestamp">
        {formatTimestamp(snapshot.json.strategy?.last_tick_timestamp)}
      </Dl>
      <Dl entry="ticks">{formatNumber(snapshot.json.strategy?.ticks || 0)}</Dl>
    </Box>
  );
};

const MarketView = ({ market }: { market: StrategyMarket }) => {
  const channel = new ChannelId({ channel: "snapshot" });
  const [snapshot, setSnapshot] = React.useState<Snapshot | null>(null);
  useWebsocket((snapshot?: Snapshot) => {
    if (
      snapshot &&
      snapshot.strategy_market &&
      snapshot.strategy_market.id === market.id
    ) {
      setSnapshot(snapshot);
    }
  }, channel);
  if (!snapshot) return null;
  return (
    <SnapshotView snapshot={snapshot} src={snapshot.json.state} name={false} />
  );
};

export default MarketHome;
