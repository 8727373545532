import { LeagueSeason } from "@fluidily/stores";
import { urlQuery } from "@metablock/core";
import { Link } from "@metablock/react";
import React from "react";

const urlProps = { underline: "none" };

export const LeagueUrl = ({ league }: { league: LeagueSeason }) => (
  <Link {...urlProps} to={`/admin/leagues/${league.id}`}>
    {league.full_name}
  </Link>
);

export const LeaguesUrl = ({
  text,
  ...query
}: {
  text: string;
  [key: string]: any;
}) => {
  const url = urlQuery("/admin/leagues", query);
  return (
    <Link to={url} {...urlProps}>
      {text}
    </Link>
  );
};
