import Page from "@fluidily/Views/Page";
import { Exchange, useStores } from "@fluidily/stores";
import { gridFullHeight } from "@fluidily/theme";
import { ApiDataGrid, Link } from "@metablock/react";
import MuiLink from "@mui/material/Link";
import React from "react";

const Exchanges: React.FC = (props: any) => {
  const { fluidily } = useStores();
  const api = fluidily.exchanges.loader();

  const columns: any[] = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "name",
      name: "Name",
      renderCell: ({ row }: { row: Exchange }) => (
        <Link to={`/data/exchanges/${row.name}`}>{row.name}</Link>
      ),
    },
    {
      key: "url",
      name: "Home page",
      renderCell: ({ row }: { row: Exchange }) =>
        row.url ? (
          <MuiLink href={row.url} target="_blank">
            {row.url}
          </MuiLink>
        ) : null,
    },
  ];

  return (
    <Page title="Exchanges">
      <ApiDataGrid
        title="Trading Accounts"
        columns={columns}
        api={api}
        sx={gridFullHeight()}
        style={{ flex: 1 }}
      />
    </Page>
  );
};

export default Exchanges;
