import React from "react";
import "react-data-grid/lib/styles.css";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountHome from "./Account";
import AdminHome from "./Admin";
import DataHome from "./Data";
import Messages from "./Messages";
import OrgHome from "./Orgs";
import AppBar from "./Views/AppBar";
import { useStores } from "./stores";

const Private = () => {
  const { userStore } = useStores();
  const org = userStore.current?.additional_info?.default_org;
  const home = org ? `/orgs/${org}` : "/account";
  return (
    <>
      <AppBar />
      <Messages />
      <Routes>
        <Route path="/data/*" element={<DataHome />} />
        <Route path="/account/*" element={<AccountHome />} />
        <Route path="/admin/*" element={<AdminHome />} />
        <Route path="/orgs/:orgName/*" element={<OrgHome />} />
        <Route path="/" element={<Navigate to={home} />} />
      </Routes>
    </>
  );
};

export default Private;
