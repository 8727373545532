import { DataApi } from "@metablock/core";
import { StrategyMarket } from "../protos";
import HttpComponent from "./httpComponent";

class Markets extends HttpComponent {
  loader(query?: any): DataApi<StrategyMarket> {
    return this.cli.loader(`${this.cli.apiUrl}/markets`, query);
  }

  async create(body: any): Promise<StrategyMarket> {
    const url = `${this.cli.apiUrl}/markets`;
    const response = await this.cli.post(url, { body });
    return response.data as StrategyMarket;
  }

  async get(id: string | number): Promise<StrategyMarket> {
    const url = `${this.cli.apiUrl}/markets/${id}`;
    const response = await this.cli.get(url);
    return response.data as StrategyMarket;
  }

  async update(id: number, body: any): Promise<StrategyMarket> {
    const url = `${this.cli.apiUrl}/markets/${id}`;
    const response = await this.cli.patch(url, { body });
    return response.data as StrategyMarket;
  }

  async delete(id: number | string): Promise<void> {
    const url = `${this.cli.apiUrl}/markets/${id}`;
    await this.cli.delete(url);
  }
}

export default Markets;
