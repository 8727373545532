import { Fluidily } from "./client";

class BaseStore {
  fluidily: Fluidily;

  constructor(fluidily: Fluidily) {
    this.fluidily = fluidily;
  }
}

export default BaseStore;
