import { DataApi } from "@metablock/core";
import HttpComponent from "./httpComponent";
import { Account, ApiKey } from "./interfaces";

class Accounts extends HttpComponent {
  async getBankingAuthLink(query?: any): Promise<string> {
    const url = this.urlQuery(`${this.cli.apiUrl}/_tl`, query);
    const response = await this.cli.get(url);
    return response.data.url;
  }

  loader(query?: any): DataApi<Account> {
    return this.cli.loader(`${this.cli.apiUrl}/accounts`, query);
  }

  async get(name_or_id: string): Promise<Account> {
    const url = `${this.cli.apiUrl}/accounts/${name_or_id}`;
    const response = await this.cli.get(url);
    return response.data as Account;
  }

  async create(body: any): Promise<Account> {
    const url = `${this.cli.apiUrl}/accounts`;
    const response = await this.cli.post(url, { body });
    return response.data as Account;
  }

  async update(id: number, body: any): Promise<Account> {
    const url = `${this.cli.apiUrl}/accounts/${id}`;
    const response = await this.cli.patch(url, { body });
    return response.data as Account;
  }

  async delete(id: number): Promise<void> {
    const url = `${this.cli.apiUrl}/accounts/${id}`;
    await this.cli.delete(url);
  }

  async apiKeys(id: number): Promise<ApiKey[]> {
    const url = `${this.cli.apiUrl}/accounts/${id}/api_keys`;
    const response = await this.cli.get(url);
    return response.data as ApiKey[];
  }

  async updateApiKey(id: number, body: any): Promise<ApiKey> {
    const url = `${this.cli.apiUrl}/accounts/${id}/api_keys`;
    const response = await this.cli.put(url, { body });
    return response.data as ApiKey;
  }
}

export default Accounts;
