import { useStores } from "@fluidily/stores";
import { Page, SignIn } from "@metablock/react";
import Typography from "@mui/material/Typography";
import React from "react";
import { Navigate } from "react-router-dom";

export const PageLogin = () => {
  const { authStore, userStore } = useStores();
  const onSuccess = () => <Navigate to="/"></Navigate>;
  if (userStore.current) return onSuccess();
  return (
    <Page title="Sign in to Fluidily">
      <SignIn authStore={authStore} onSuccess={onSuccess} Header={Header} />
      <Typography
        component="p"
        variant="subtitle1"
        align="center"
        color="textSecondary"
      >
        Sign in is enabled only for a selected group of emails
      </Typography>
    </Page>
  );
};

const Header = () => (
  <Typography component="h1" variant="h5" paragraph>
    Sign in to Fluidily
  </Typography>
);

export default PageLogin;
