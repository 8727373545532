export class SecurityKey {
  name: string;
  exchange: string;

  constructor(name: string, exchange: string) {
    this.name = name;
    this.exchange = exchange;
  }

  get fullName(): string {
    return securityFullName(this);
  }

  static fromMessage(msg: any): SecurityKey {
    return new SecurityKey(msg.name, msg.exchange);
  }

  static fromFullName(fullName: string): SecurityKey {
    const [name, exchange] = fullName.split("@");
    return new SecurityKey(name, exchange);
  }
}

export interface Pair {
  base: string;
  quote: string;
}

export interface Margin {
  currency: string;
  initial: number;
  maintenance: number;
}

export interface Settlement {
  currency: string;
  expiry: Date | null;
}

export interface Security {
  security_key: SecurityKey;
  security_type: string;
  pair: Pair;
  contract_value: number;
  multiplier: number;
  tick_size: number;
  margin: Margin;
  settlement: Settlement;
  exchange_symbol: string;
  inverse: boolean;
  quanto: boolean;
  underlying: string;
  min_size: number;
  lot_size: number;
  leverage: number;
  max_size: number;
  option_type: string;
  strike: number;
}

export const securityFullName = (securityKey: SecurityKey): string =>
  `${securityKey.name}@${securityKey.exchange}`;
