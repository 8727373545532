import MuiBox from "@mui/material/Box";
import React from "react";

interface BoxProps {
  pb?: number;
  children: any;
}

export const Box = (props: BoxProps) => {
  const { children, pb = 2 } = props;
  return <MuiBox pb={pb}>{children}</MuiBox>;
};

export default Box;
