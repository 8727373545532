import { getBlock } from "@metablock/core";
import {
  createStores as createMetablockStores,
  MetablockStore,
} from "@metablock/store";
import AccountStore from "./account";
import { Fluidily } from "./client";
import { EntitiesStore } from "./entities";
import { LeagueStore } from "./sport";
import StreamingStore from "./streaming";
import { TasksStore } from "./tasks";
import urls from "./urls";
import WebStore from "./web";

const block = getBlock();

export interface FluidilyStore extends MetablockStore {
  fluidily: Fluidily;
  accountStore: AccountStore;
  leagueStore: LeagueStore;
  entitiesStore: EntitiesStore;
  streamingStore: StreamingStore;
  taskStore: TasksStore;
  webStore: WebStore;
}

export const createStores = (): FluidilyStore => {
  const fluidily = new Fluidily({ baseUrl: urls.rest });
  const account = block.plugins.account;
  const stores = createMetablockStores(account.api_url, block.name);

  return {
    accountStore: new AccountStore(fluidily),
    leagueStore: new LeagueStore(fluidily),
    entitiesStore: new EntitiesStore(fluidily),
    streamingStore: new StreamingStore(urls.streaming),
    taskStore: new TasksStore(fluidily),
    webStore: new WebStore(),
    fluidily,
    ...stores,
  };
};
