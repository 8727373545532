import { DataApi } from "@metablock/core";
import HttpComponent from "./httpComponent";
import { Account, Entity } from "./interfaces";

class Entities extends HttpComponent {
  async get(id: string): Promise<Entity> {
    const response = await this.cli.get(`${this.cli.apiUrl}/entities/${id}`);
    return response.data as Entity;
  }

  loader(query?: any): DataApi<Account> {
    return this.cli.loader(`${this.cli.apiUrl}/entities`, query);
  }

  async create(body: Record<string, any>): Promise<Entity> {
    const url = `${this.cli.apiUrl}/entities`;
    const response = await this.cli.post(url, {
      body,
    });
    return response.data as Entity;
  }

  async createAccount(
    entity: Entity,
    body: Record<string, any>,
  ): Promise<Account> {
    const url = `${this.cli.apiUrl}/entities/${entity.id}/accounts`;
    const response = await this.cli.post(url, {
      body,
    });
    return response.data as Account;
  }
}

export default Entities;
