import DLFactory from "@fluidily/Components/DescriptionList";
import { EditIcon, PublishIcon } from "@fluidily/Components/Icons";
import Panel from "@fluidily/Components/Panel";
import { BooleanIcon } from "@fluidily/Components/booleanFormatter";
import Page from "@fluidily/Views/Page";
import { LeaguesUrl } from "@fluidily/Views/Urls";
import { useStores } from "@fluidily/stores";
import { formatDate } from "@fluidily/utils/dates";
import { round } from "@fluidily/utils/money";
import { NotFound } from "@metablock/react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { green, red, yellow } from "@mui/material/colors";
import React from "react";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";

export const MatchingScore = ({
  score,
  component,
  sx,
}: {
  score: number;
  component?: any;
  sx?: any;
}) => {
  const round1 = round(1);
  let backgroundColor: string = green[900];
  let color: string = "inherit";
  score = score || 0;
  if (score < 60) {
    backgroundColor = red[900];
  } else if (score < 75) {
    backgroundColor = yellow[900];
    color = "black";
  } else if (score < 85) {
    backgroundColor = green[500];
  }
  return (
    <Box
      component={component || "div"}
      sx={{ backgroundColor, color, ...sx }}
    >{`${round1(score)}%`}</Box>
  );
};

const League = (props: any) => {
  const { countryMap, ops } = props;
  const { leagueId } = useParams() as { leagueId: string };
  const { fluidily } = useStores();
  const [editing, setEdit] = React.useState(false);
  const changes = React.useRef<any>(undefined);
  const action = editing ? "submit" : "edit";
  const { loading, value } = useAsync(async () => {
    let league = await fluidily.leagues.getLeague(leagueId);
    let leagues = [];
    if (editing) {
      leagues = await fluidily.leagues.getExchangeLeagues({
        exchange: "betfair",
        event_type: league.event_type,
      });
    } else if (changes.current) {
      const newMapping = changes.current;
      changes.current = undefined;
      league = await ops.leagueUpdate(league.id, {
        tracked: league.tracked,
        extra: newMapping,
      });
    }

    return { league, leagues };
  }, [leagueId, editing]);
  if (loading) return null;
  if (!value) return <NotFound />;
  const Dl = DLFactory({ sm: 4 });
  const { league, leagues } = value;
  const country = countryMap[league.country];
  const mapping = changes.current || league.extra || {};
  const onChange = async (betfair_id: string) => {
    const betfair = leagues.find((l: any) => l.id === betfair_id);
    if (betfair) {
      changes.current = {
        ...mapping,
        betfair_id,
        betfair_name: betfair.name,
        betfair_matching_score: 100,
      };
    }
  };
  return (
    <Page title={`${league.full_name} ${league.season}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Panel title="League">
            <Dl entry="Name">{league.full_name}</Dl>
            <Dl entry="ID">{league.id}</Dl>
            <Dl entry="Season">{league.season}</Dl>
            <Dl entry="Current">
              <BooleanIcon value={league.current} color />
            </Dl>
            <Dl entry="Tracked">
              <BooleanIcon value={league.tracked} color />
            </Dl>
            <Dl entry="Country">{country ? country.name : league.country}</Dl>
            <Dl entry="Type">{league.league_type}</Dl>
            <Dl entry="Event Type">
              <LeaguesUrl
                text={league.event_type}
                event_type={league.event_type}
              />
            </Dl>
            {league.event_type === "TENNIS" ? (
              <Dl entry="Surface">{league.surface}</Dl>
            ) : null}
            <Dl entry="Sex">{league.sex}</Dl>
            {league.start ? (
              <Dl entry="start">{formatDate(new Date(league.start))}</Dl>
            ) : null}
            {league.end ? (
              <Dl entry="end">{formatDate(new Date(league.end))}</Dl>
            ) : null}
            {league.extra.tennisexplorer_url ? (
              <Dl entry="Data">
                <Link
                  href={league.extra.tennisexplorer_url}
                  target="_blank"
                  underline="none"
                  rel="noopener"
                >
                  tennis explorer
                </Link>
              </Dl>
            ) : null}
          </Panel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Panel
            title="Betfair Mapping"
            action={
              <Tooltip title={action} placement="top-end">
                <IconButton
                  aria-label={action}
                  onClick={() => setEdit(!editing)}
                >
                  {editing ? <PublishIcon /> : <EditIcon />}
                </IconButton>
              </Tooltip>
            }
          >
            <Dl entry="Name">
              {editing ? (
                <EditMapping
                  value={mapping.betfair_id}
                  leagues={leagues}
                  onChange={onChange}
                />
              ) : (
                mapping.betfair_name
              )}
            </Dl>
            <Dl entry="ID">{mapping.betfair_id}</Dl>
            <Dl entry="Matching Score">
              <MatchingScore
                component="span"
                score={mapping.betfair_matching_score || 0}
                sx={{ p: 1 }}
              />
            </Dl>
          </Panel>
        </Grid>
      </Grid>
    </Page>
  );
};

const EditMapping = ({
  value,
  leagues,
  onChange,
}: {
  value: string;
  leagues: any;
  onChange: any;
}) => {
  const [current, setValue] = React.useState(value);
  onChange(value);
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onChange(event.target.value as string);
  };
  leagues.sort((a: any, b: any) => a.name.localeCompare(b.name));
  return (
    <Select value={current} onChange={handleChange}>
      {leagues.map((league: any) => (
        <MenuItem key={league.id} value={league.id}>
          {`${league.name} - ${league.id} - ${league.country_code}`}
        </MenuItem>
      ))}
    </Select>
  );
};

export default League;
