import { Snapshot } from "@fluidily/stores";
import React from "react";
import ReactJson from "react-json-view";

const SnapshotView = ({
  snapshot,
  src,
  ...props
}: {
  snapshot: Snapshot;
  src?: any;
  [x: string]: any;
}) => {
  const json = src || snapshot.json;
  return <ReactJson src={json} theme="tomorrow" {...props} />;
};

export default SnapshotView;
