import { timeFormat } from "d3-time-format";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

const NANOS_PER_MILLIS = 1000000;

// @ts-ignore
TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

export const timestampToDate = (nanos: number): Date => {
  return new Date(nanos / NANOS_PER_MILLIS);
};

export const formatDate = timeFormat("%d %b %Y");
export const formatDateTime = timeFormat("%H:%M %d %b %Y");
export const formatDateTimeSeconds = timeFormat("%d/%b/%y %H:%M:%S");
export const formatTime = timeFormat("%H:%M:%S");

export const formatTimestamp = (nanos: number) =>
  formatTime(timestampToDate(nanos));

export const formatTimeAgo = (date: Date) => timeAgo.format(date);

export const isString = (value: any): boolean =>
  Object.prototype.toString.call(value) === "[object String]";
