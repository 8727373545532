import { booleanFormatter } from "@fluidily/Components";
import rowDate from "@fluidily/Components/dateFormatter";
import deleteEntry from "@fluidily/Views/deleteEntry";
import { Dashboard, useStores } from "@fluidily/stores";
import { formatDateTime } from "@fluidily/utils/dates";
import { ApiDataGrid, Link } from "@metablock/react";
import React from "react";
import OrgView from "../Views/OrgView";

const Dashboards = ({
  basePath,
  ...props
}: {
  basePath: string;
  [x: string]: any;
}) => {
  const { fluidily } = useStores();
  const [ignored, render] = React.useState<any>({});
  const api = fluidily.dashboards.loader();

  const deleteDashboard = async (account: Dashboard) => {
    await fluidily.dashboards.delete(account.id);
    render({});
  };

  const columns: any[] = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "name",
      name: "Name",
      renderCell: ({ row }: { row: Dashboard }) => (
        <Link to={`${basePath}/${row.name}`}>{row.name}</Link>
      ),
    },
    {
      key: "default",
      name: "Default",
      renderCell: booleanFormatter("default"),
    },
    {
      key: "created_at",
      name: "Created",
      renderCell: rowDate("created_at", formatDateTime),
    },
    deleteEntry(
      deleteDashboard,
      (row: Dashboard) => `Delete dashboard ${row.name}`,
    ),
  ];

  return (
    <OrgView title="dashboards" {...props}>
      <ApiDataGrid columns={columns} api={api} />
    </OrgView>
  );
};

export default Dashboards;
