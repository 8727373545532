import HttpComponent from "./httpComponent";
import { Flamegraphs } from "./interfaces";

class Dev extends HttpComponent {
  async flamegraphs(date: string, target: string): Promise<Flamegraphs[]> {
    const url = `${this.cli.apiUrl}/flamegraphs/${date}/${target}`;
    const response = await this.cli.get(url);
    return response.data as Flamegraphs[];
  }
}

export default Dev;
