import YesIcon from "@mui/icons-material/CheckCircle";
import NoIcon from "@mui/icons-material/RadioButtonUnchecked";
import Typography from "@mui/material/Typography";
import React from "react";

const booleanFormatter = (code: string, action?: any, color?: boolean) => {
  return ({ row }: any) => {
    const value = row[code];
    const props: any = {};
    if (action) props.onClick = () => action(row);
    if (color) props.color = value ? "success" : "disabled";
    return (
      <Typography align="center">
        {value ? <YesIcon {...props} /> : <NoIcon {...props} />}
      </Typography>
    );
  };
};

export const BooleanIcon = ({
  value,
  color,
}: {
  value: boolean;
  color?: boolean;
}) => {
  const props: any = color ? { color: value ? "success" : "disabled" } : {};
  return value ? <YesIcon {...props} /> : <NoIcon {...props} />;
};

export default booleanFormatter;
