import {
  formatDateTimeSeconds,
  isString,
  timestampToDate,
} from "@fluidily/utils/dates";
import Box from "@mui/material/Box";
import React from "react";

const rowDate = (code: any, converter?: any, textAlign?: string) => {
  converter = converter || formatDateTimeSeconds;
  return ({ row }: any): string => {
    const ts = isString(code) ? row[code] : code(row);
    if (!ts) return "";
    const dte = typeof ts === "string" ? new Date(ts) : timestampToDate(ts);
    const converted = converter(dte);
    return textAlign ? <Box sx={{ textAlign }}>{converted}</Box> : converted;
  };
};

export default rowDate;
