import Page from "@fluidily/Views/Page";
import { useStores } from "@fluidily/stores";
import { NotFound } from "@metablock/react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountHome from "./Home";
import Tokens from "./Tokens";
import UpdateProfile from "./UpdateProfile";
import Details from "./Views/Details";

const AccountIndex = (props: any) => {
  const { userStore } = useStores();
  const basePath = "account";
  const tabs = React.useMemo(
    () => [
      { text: "account", to: `/${basePath}` },
      { text: "settings", to: `/${basePath}/settings` },
      { text: "tokens", to: `/${basePath}/tokens` },
    ],
    [],
  );
  if (!userStore.current) {
    return <Navigate to="/" />;
  }

  const WithAccount = ({
    Component,
    title,
  }: {
    Component: any;
    title: string;
  }) => (
    <Page tabs={tabs} title={title}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={4} lg={3}>
          <Details />
        </Grid>
        <Grid item sm={12} md={8} lg={9}>
          <Component {...props} />
        </Grid>
      </Grid>
    </Page>
  );

  return (
    <Routes>
      <Route
        path=""
        element={<WithAccount Component={AccountHome} title="Account" />}
      />
      <Route
        path="/settings"
        element={<WithAccount Component={UpdateProfile} title="Settings" />}
      />
      <Route
        path="/tokens"
        element={<WithAccount Component={Tokens} title="API Tokens" />}
      />
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default observer(AccountIndex);
