import Panel from "@fluidily/Components/Panel";
import { ExchangeId } from "@fluidily/Views/ExchangeSymbol";
import SnapshotView from "@fluidily/Views/Snapshot";
import { useWebsocket } from "@fluidily/hooks";
import {
  ChannelId,
  Snapshot,
  TradableBettingEvent,
  bettingEventVolume,
  tradable_betting_event_uid,
  useStores,
} from "@fluidily/stores";
import { formatDateTime } from "@fluidily/utils/dates";
import { formatNumber } from "@fluidily/utils/money";
import { Link, NotFound } from "@metablock/react";
import { Grid } from "@mui/material";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import OrgView from "../Views/OrgView";
import EventService from "./EventService";
import FootballView from "./Football";
import Markets from "./Markets";
import { SnapshotPanel } from "./Snapshot";
import TennisView from "./Tennis";
import { Dl, panelProps } from "./Utils";

const EventHome = ({ basePath }: { basePath: string }) => {
  const { fluidily } = useStores();
  const { eventId } = useParams() as { eventId: string };
  const [data, updateEvent] = React.useState<any>(null);

  const { loading, value } = useAsync(async () => {
    if (data) return fluidily.betting_events.update_tradable(eventId, data);
    else return fluidily.betting_events.get_tradable(eventId);
  }, [eventId, data]);
  if (loading) return null;
  const nav = [
    { text: "view", to: `${basePath}/${eventId}` },
    { text: "markets", to: `${basePath}/${eventId}/markets` },
    { text: "json", to: `${basePath}/${eventId}/json` },
    { text: "params", to: `${basePath}/${eventId}/params` },
  ];

  if (!value)
    return (
      <OrgView title="Not found" left={nav}>
        <NotFound />;
      </OrgView>
    );

  const tradableEvent = value as TradableBettingEvent;
  const event = tradableEvent.betting_event;
  const title = `${tradableEvent.strategy} - ${event.full_name}`;
  return (
    <OrgView title={title} left={nav}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Panel title="Event details" {...panelProps()}>
            <Dl entry="name">{event.full_name}</Dl>
            <Dl entry="competition">
              <Link
                to={`/admin/leagues/${event.league_season.id}`}
                underline="none"
              >
                {event.league_season.full_name}
              </Link>
            </Dl>
            <Dl entry="type">
              <Link
                to={`${basePath}?event_type=${event.event_type}`}
                underline="none"
              >
                {event.event_type}
              </Link>
            </Dl>
            <Dl entry="sex">{event.league_season.sex}</Dl>
            <Dl entry="strategy">
              <Link
                to={`${basePath}?strategy=${tradableEvent.strategy}`}
                underline="none"
              >
                {tradableEvent.strategy}
              </Link>
            </Dl>
            <Dl entry="event">{event.state}</Dl>
            <Dl entry="start">{formatDateTime(new Date(event.start))}</Dl>
            <Dl entry="volume">
              {formatNumber(Math.round(bettingEventVolume(event)))}
            </Dl>
            <Dl entry="state">
              <EventTrading
                tradableEvent={tradableEvent}
                updateEvent={updateEvent}
              />
            </Dl>
            <Dl entry="tradable ID">{tradableEvent.id}</Dl>
            <Dl entry="event ID">{event.id}</Dl>
            <Dl entry="exchange ID">
              <ExchangeId betting_event={event} />
            </Dl>
          </Panel>
          <SnapshotPanel tradableEvent={tradableEvent} basePath={basePath} />
          <EventService tradableEvent={tradableEvent} basePath={basePath} />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9}>
          <EventView tradableEvent={tradableEvent} />
        </Grid>
      </Grid>
    </OrgView>
  );
};

const EventTrading = ({
  tradableEvent,
  updateEvent,
}: {
  tradableEvent: TradableBettingEvent;
  updateEvent: any;
}) => {
  const variant = (state: string) =>
    tradableEvent.trading_state === state ? "contained" : "outlined";

  const onClick = (event: any) => {
    const state = event.currentTarget.textContent;
    if (state !== tradableEvent.trading_state) {
      updateEvent({
        trading_state: state,
      });
    }
  };

  const sx = { pl: 0.5, pr: 0.5, pt: 0, pb: 0 };
  return (
    <ButtonGroup>
      {["TRADING", "ACTIVE", "INACTIVE"].map((state) => (
        <Button
          size="small"
          key={state}
          variant={variant(state)}
          sx={sx}
          onClick={onClick}
        >
          {state}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const EventView = ({
  tradableEvent,
}: {
  tradableEvent: TradableBettingEvent;
}) => {
  const channel = new ChannelId({ channel: "snapshot" });
  const [snapshot, setSnapshot] = React.useState<Snapshot | null>(null);
  useWebsocket((snapshot?: Snapshot) => {
    if (snapshot?.uid === tradable_betting_event_uid(tradableEvent)) {
      setSnapshot(snapshot);
    }
  }, channel);
  const eventType = tradableEvent.betting_event.event_type;
  let Component = JsonView;
  if (eventType === "FOOTBALL") {
    Component = FootballView;
  } else if (eventType === "TENNIS") {
    Component = TennisView;
  }
  return (
    <Routes>
      <Route
        path=""
        element={
          <Component tradableEvent={tradableEvent} snapshot={snapshot} />
        }
      />
      <Route
        path="markets"
        element={<Markets tradableEvent={tradableEvent} snapshot={snapshot} />}
      />
      <Route
        path="json"
        element={<JsonView tradableEvent={tradableEvent} snapshot={snapshot} />}
      />
      <Route
        path="params"
        element={
          <JsonParamsView tradableEvent={tradableEvent} snapshot={snapshot} />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const JsonView = ({
  tradableEvent,
  snapshot,
}: {
  tradableEvent: TradableBettingEvent;
  snapshot: any;
}) => {
  if (!snapshot) return null;
  return (
    <SnapshotView snapshot={snapshot} src={snapshot.json.state} name={false} />
  );
};

const JsonParamsView = ({
  tradableEvent,
  snapshot,
}: {
  tradableEvent: TradableBettingEvent;
  snapshot: any;
}) => {
  const te = snapshot?.market?.TradableBettingEvent || tradableEvent;
  return <SnapshotView snapshot={snapshot} src={te.params} name={false} />;
};

export default EventHome;
