import { Box } from "@mui/material";
import React from "react";

export const sxRight = () => ({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
});

const RightBox = (props: any) => {
  const { sx, ...extra } = props;
  return <Box sx={{ ...sxRight(), ...sx }} {...extra} />;
};

export default RightBox;
