import { DashboardIcon, DeleteIcon } from "@fluidily/Components/Icons";
import DashboardPanels from "@fluidily/Dashboards";
import { SelectDashboardPanel } from "@fluidily/Views/DashboardPanels";
import { Dashboard, useStores } from "@fluidily/stores";
import { NotFound } from "@metablock/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import OrgView from "../Views/OrgView";
import { DashboardConfig } from "./Default";
import nav from "./nav";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardHome = ({ basePath }: { basePath: string }) => {
  const { fluidily } = useStores();
  const { dashboardName } = useParams() as { dashboardName: string };

  const { loading, value } = useAsync(async () => {
    return fluidily.dashboards.get(dashboardName);
  });
  if (loading) return null;
  if (!value) return <NotFound />;
  return <LoadedDashBoard dashboard={value} basePath={basePath} />;
};

interface LayoutElement {
  element: HTMLElement;
  layout: Layout;
  panel?: string;
}

const LoadedDashBoard = ({
  dashboard,
  basePath,
  borderWidth,
}: {
  dashboard: Dashboard;
  basePath: string;
  borderWidth?: number;
}) => {
  const { fluidily, webStore } = useStores();
  const [layoutEl, setLayoutEl] = React.useState<null | LayoutElement>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] =
    React.useState<string>("lg");
  const [state, setState] = React.useState<DashboardConfig>(
    dashboard.config as DashboardConfig,
  );
  const menuOpen = Boolean(layoutEl);
  const theme = {
    borderColor: "primary.main",
    borderRadius: 1,
    borderWidth: borderWidth || 2,
    borderStyle: "solid",
  };
  //
  // Add new panel
  const addPanel = () => {
    const config = { ...state };
    Object.keys(config.layouts).forEach((bp: string) => {
      const layout = config.layouts[bp];
      const n = Math.max(...layout.map((l: Layout) => +l.i)) + 1;
      const newLayout = { i: `${n}`, x: 0, y: 0, w: 1, h: 2 };
      layout.push(newLayout);
    });
    storeDashboard(config);
  };

  // Add new panel action to left menu
  const left = nav(basePath);
  left.push({
    text: "Add panel",
    onClick: addPanel,
  });

  /// broadcast current layout
  React.useEffect(() => {
    webStore.setDashboard({
      currentBreakpoint,
      layout: state.layouts[currentBreakpoint],
    });
  }, [state]);

  const handleCloseMenu = () => {
    setLayoutEl(null);
  };

  const handleSelectDialog = async () => {
    setDialogOpen(false);
    if (layoutEl && layoutEl.panel) {
      const panel = layoutEl.panel;
      const id = layoutEl.layout.i;
      const config = { ...state };
      config.panels[id] = panel;
      await storeDashboard(config);
    }
  };

  const handleCancelDialog = () => {
    if (layoutEl) layoutEl.panel = undefined;
    setDialogOpen(false);
  };

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    layout: Layout,
  ) => {
    console.log(layout);
    setLayoutEl({ element: event.currentTarget, layout });
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const storeDashboard = async (config: DashboardConfig) => {
    await fluidily.dashboards.update(dashboard.id, { config });
    setState(config);
  };

  const onBreakpointChange = (currentBreakpoint: string) => {
    setCurrentBreakpoint(currentBreakpoint);
  };

  const onLayoutChange = (
    layout: Layout[],
    layouts: Record<string, Layout[]>,
  ) => {
    storeDashboard({ ...state, layouts });
  };

  const removeLayout = async () => {
    const layout = layoutEl?.layout;
    if (!layout) return;
    const config = { ...state };
    Object.keys(config.layouts).forEach((bp: string) => {
      config.layouts[bp] = config.layouts[bp].filter((l: Layout) => {
        return l.i !== layout.i;
      });
    });
    handleCloseMenu();
    await storeDashboard(config);
  };

  const onPanelChange = (panel: string) => {
    if (!layoutEl) return;
    layoutEl.panel = panel;
  };

  return (
    <OrgView title={dashboard.name} left={left}>
      <ResponsiveGridLayout
        className="layout"
        layouts={state.layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        // WidthProvider option
        measureBeforeMount={false}
        compactType={dashboard.config.compactType}
      >
        {state.layouts.lg.map((layout: Layout, index: number) => {
          return (
            <Box component="section" sx={theme} key={index}>
              <Box pr={1} pl={1} sx={{ width: "100%" }}>
                <IconButton
                  aria-label="settings"
                  size="small"
                  onClick={(event) => openMenu(event, layout)}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Panel sx={theme} panel={state.panels[layout.i]} />
            </Box>
          );
        })}
      </ResponsiveGridLayout>
      <Menu
        id="basic-menu"
        anchorEl={layoutEl?.element}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={removeLayout}>
          <DeleteIcon />
          Remove
        </MenuItem>
        <MenuItem onClick={openDialog}>
          <DashboardIcon /> Select dashboard panel
        </MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Select dashboard panel
        </DialogTitle>
        <DialogContent>
          <SelectDashboardPanel onChange={onPanelChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSelectDialog} variant="outlined" autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </OrgView>
  );
};

const Panel = ({ sx, panel }: { sx: any; panel: any }) => {
  const [height, setHeight] = React.useState(0);
  const setRef = (element: HTMLDivElement) => {
    if (element && element.parentElement) {
      setHeight(
        element.parentElement.offsetHeight -
          element.offsetTop -
          2 * sx.borderWidth,
      );
    }
  };
  const Panel = panel ? DashboardPanels[panel] : null;
  if (!Panel) return null;
  return (
    <Box
      ref={setRef}
      sx={{
        overflow: "auto",
        width: "100%",
        height,
      }}
    >
      <Panel />
    </Box>
  );
};

export default DashboardHome;
