import { ControlField, SchemaEntryProps } from "@metablock/react";
import React from "react";
import ReactJson from "react-json-view";

const MarketParams = ({ name, form, schema }: SchemaEntryProps) => {
  const value = form.data[name] || {};

  const onChange = (d: any) => {
    form.setValue(name, d.updated_src);
  };

  return (
    <ControlField name={name} form={form} margin={form.margin} fullWidth>
      <ReactJson
        theme="monokai"
        src={value}
        onEdit={onChange}
        onAdd={onChange}
        onDelete={onChange}
        name={name}
        collapsed={false}
        style={{ padding: 8 }}
        enableClipboard={(copy) => {
          console.log("you copied to clipboard!", copy);
        }}
      />
    </ControlField>
  );
};

export default MarketParams;
