import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";

const deleteEntry = (deleteAction: any, tooltip?: any) => {
  return {
    name: "",
    key: "",
    width: 30,
    renderCell: ({ row }: any) => {
      const title = tooltip ? tooltip(row) : `Delete ${row.id}`;
      return (
        <Tooltip title={title}>
          <IconButton color="error" onClick={() => deleteAction(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
};

export default deleteEntry;
