import { DataApi } from "@metablock/core";
import { Fluidily, League } from "./client";

class LeagueStore {
  fluidily: Fluidily;

  constructor(cli: Fluidily) {
    this.fluidily = cli;
  }

  loader(query?: Record<string, any>): DataApi<League> {
    return this.fluidily.loader(`${this.fluidily.apiUrl}/leagues`, query);
  }
}

export { LeagueStore };
