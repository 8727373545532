import { useStores } from "@fluidily/stores";
import { Box } from "@mui/material";
import React from "react";
import { Layout } from "react-grid-layout";
import { Navigate } from "react-router-dom";
import { useAsync } from "react-use";

export interface DashboardConfig {
  compactType: "vertical" | "horizontal";
  layouts: Record<string, Layout[]>;
  panels: Record<string, string>;
}

export const defaultLayouts = () => {
  return {
    xxs: [
      { i: "0", x: 0, y: 0, w: 1, h: 2 },
      { i: "1", x: 1, y: 0, w: 3, h: 2 },
    ],
    xs: [
      { i: "0", x: 0, y: 0, w: 1, h: 2 },
      { i: "1", x: 1, y: 0, w: 3, h: 2 },
    ],
    sm: [
      { i: "0", x: 0, y: 0, w: 1, h: 2 },
      { i: "1", x: 1, y: 0, w: 3, h: 2 },
    ],
    md: [
      { i: "0", x: 0, y: 0, w: 1, h: 2 },
      { i: "1", x: 1, y: 0, w: 3, h: 2 },
    ],
    lg: [
      { i: "0", x: 0, y: 0, w: 1, h: 2 },
      { i: "1", x: 1, y: 0, w: 3, h: 2 },
    ],
  };
};

export const defaultConfig = (): DashboardConfig => ({
  compactType: "vertical",
  layouts: defaultLayouts(),
  panels: {},
});

const DefaultDashboard = ({ basePath }: { basePath: string }) => {
  const { fluidily } = useStores();
  const loader = fluidily.dashboards.loader({ default: true });

  const { loading, value } = useAsync(async () => {
    await loader.loadData();
    if (loader.data.length > 0) {
      return loader.data[0];
    } else {
      return await fluidily.dashboards.create({
        name: "default",
        default: true,
        config: defaultConfig(),
      });
    }
  });
  if (loading) return null;
  if (!value) return <Box>could not load dashboard</Box>;
  return <Navigate to={`${basePath}/${value.name}`} replace={true} />;
};

export default DefaultDashboard;
