import Page from "@fluidily/Views/Page";
import { Exchange, useStores } from "@fluidily/stores";
import { CrudForm } from "@metablock/react";
import React from "react";

const UpdateExchange = ({
  exchange,
  ...props
}: {
  exchange: Exchange;
  [x: string]: any;
}) => {
  const { fluidily } = useStores();
  const schemaLoader = fluidily.schemaLoader("ExchangeUpdate");

  const getSchema = async () => {
    return await schemaLoader();
  };

  const submit = async (data: any) => {
    if (Object.keys(data).length > 0) {
      await fluidily.exchanges.update(exchange.id, data);
    }
  };

  return (
    <Page title={`Update exchange ${exchange.name}`} {...props}>
      <CrudForm
        defaults={exchange}
        schema={getSchema}
        submit={submit}
        label="Update exchange"
      />
    </Page>
  );
};

export default UpdateExchange;
