import Panel from "@fluidily/Components/Panel";
import { useWebsocket } from "@fluidily/hooks";
import {
  ChannelId,
  Snapshot,
  TradableBettingEvent,
  bettingEventVolume,
  tradable_betting_event_uid,
} from "@fluidily/stores";
import { formatTimestamp } from "@fluidily/utils/dates";
import { formatNumber } from "@fluidily/utils/money";
import React from "react";
import { Dl, panelProps } from "./Utils";

type SnapshotFn = (
  snapshot: Snapshot | null,
  market?: TradableBettingEvent,
) => any;

export const FromSnapshot = ({
  market,
  fn,
}: {
  market: TradableBettingEvent;
  fn: SnapshotFn;
}) => {
  const channel = new ChannelId({ channel: "snapshot" });
  const [snapshot, setSnapshot] = React.useState<Snapshot | null>(null);
  useWebsocket((snapshot?: Snapshot) => {
    if (snapshot?.uid === tradable_betting_event_uid(market)) {
      setSnapshot(snapshot);
    }
  }, channel);
  return fn(snapshot, market);
};

export const fromSnapshot = (fn: SnapshotFn) => {
  return ({ row }: { row: TradableBettingEvent }) => {
    return <FromSnapshot market={row} fn={fn} />;
  };
};

export const SnapshotPanel = ({
  tradableEvent,
  basePath,
}: {
  tradableEvent: TradableBettingEvent;
  basePath: string;
}) => {
  return (
    <Panel title="Snapshot" {...panelProps()}>
      <FromSnapshot market={tradableEvent} fn={innerPanel} />
    </Panel>
  );
};

const innerPanel = (
  snapshot: Snapshot | null,
  market?: TradableBettingEvent,
) => {
  if (snapshot === null) return null;
  //@ts-ignore
  const te = snapshot.market?.TradableBettingEvent || {};
  const be = te.betting_event;
  const volume = be ? formatNumber(Math.round(bettingEventVolume(be))) : "";
  return (
    <>
      <Dl entry="Service">{snapshot.service.uid}</Dl>
      <Dl entry="Timestamp">
        {formatTimestamp(snapshot.json.strategy?.last_tick_timestamp)}
      </Dl>
      <Dl entry="volume">{volume}</Dl>
      <Dl entry="state">{snapshot.json.strategy?.state}</Dl>
      <Dl entry="ticks">{formatNumber(snapshot.json.strategy?.ticks || 0)}</Dl>
    </>
  );
};
