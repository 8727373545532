import rowDate from "@fluidily/Components/dateFormatter";
import numberFormatter from "@fluidily/Components/numberFormatter";
import { Snapshot } from "@fluidily/stores";
import { formatTime, formatTimeAgo } from "@fluidily/utils/dates";
import { StaticData } from "@metablock/core";
import { ApiDataGrid, useDataGridFilters } from "@metablock/react";
import React from "react";

const Gateway = ({ snapshot }: { snapshot: Snapshot }) => {
  const dataGridFilters = useDataGridFilters(true);
  const search = dataGridFilters.filters?.search;
  const data: any[] = [];
  snapshot.json.market_data.forEach((feed: any) => {
    feed.feeds.forEach((current: any) => {
      if (current.state === "Unsubscribed" && current.updates === 0) return;
      if (search && !feed.security_key.name.includes(search)) return;
      data.push({
        security: feed.security_key.name,
        feed: current.name,
        state: current.state,
        updates: current.updates,
        frequency: current.update_frequency_millis,
        last_update: current.last_update_timestamp.total_nanos,
        state_change: current.state_change_timestamp.total_nanos,
        reconnection_count: current.reconnection_count,
        stale_millis: current.stale_millis,
      });
    });
  });
  data.sort((a: any, b: any) => {
    if (b.updates > a.updates) return 1;
    else if (b.updates < a.updates) return -1;
    else return b.security > a.security ? -1 : 1;
  });
  const api = new StaticData(data, dataGridFilters.filters);

  const columns = React.useMemo(
    () => [
      {
        key: "security",
        name: "Security",
        minWidth: 100,
      },
      {
        key: "feed",
        name: "feed",
        minWidth: 100,
      },
      {
        key: "state",
        name: "state",
        minWidth: 100,
      },
      {
        key: "updates",
        name: "updates",
        renderCell: numberFormatter("updates"),
      },
      {
        key: "frequency",
        name: "update frequency (ms)",
        renderCell: numberFormatter("frequency"),
      },
      {
        key: "reconnection_count",
        name: "reconnection count",
        renderCell: numberFormatter("reconnection_count"),
      },
      {
        key: "stale_millis",
        name: "staleness limit (ms)",
        renderCell: numberFormatter("stale_millis"),
      },
      {
        key: "last_update",
        name: "last update",
        renderCell: rowDate("last_update", formatTime, "right"),
      },
      {
        key: "state_change",
        name: "state change",
        renderCell: rowDate("state_change", formatTimeAgo),
      },
    ],
    [],
  );

  return (
    <ApiDataGrid
      api={api}
      columns={columns}
      dataGridFilters={dataGridFilters}
      style={{ height: "100vh" }}
      search
    />
  );
};

export default Gateway;
