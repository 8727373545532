import { DataApi } from "@metablock/core";
import HttpComponent from "./httpComponent";
import { Exchange } from "./interfaces";

class Exchanges extends HttpComponent {
  loader(query?: any): DataApi<Exchange> {
    return this.cli.loader(`${this.cli.apiUrl}/exchanges`, query);
  }

  async get(name_or_id: string): Promise<Exchange> {
    const url = `${this.cli.apiUrl}/exchanges/${name_or_id}`;
    const response = await this.cli.get(url);
    return response.data as Exchange;
  }

  async update(id: number, body: any): Promise<Exchange> {
    const url = `${this.cli.apiUrl}/exchanges/${id}`;
    const response = await this.cli.patch(url, { body });
    return response.data as Exchange;
  }

  async delete(id: number): Promise<void> {
    const url = `${this.cli.apiUrl}/exchanges/${id}`;
    await this.cli.delete(url);
  }
}

export default Exchanges;
