import { MonoBox } from "@fluidily/Components";
import Page from "@fluidily/Views/Page";
import { OpsUser, Task } from "@fluidily/stores";
import { formatDateTimeSeconds } from "@fluidily/utils/dates";
import { formatNumber } from "@fluidily/utils/money";
import { style } from "@fluidily/utils/table";
import { ApiDataGrid } from "@metablock/react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import React from "react";
import { SelectCellFormatter } from "react-data-grid";

const taskColor: Record<string, string> = {
  success: "success",
  failure: "error",
};
const taskUrl = (name: string) =>
  `https://grafana.sso.metablock.io/d/7EpCDjjGz/tasks?var-task=${name}`;

const logUrl = (name: string) =>
  `https://grafana.sso.metablock.io/explore?orgId=1&left=%5B%22now-1h%22,%22now%22,%22Loki%22,%7B%22exemplar%22:true,%22expr%22:%22%7Bapp%3D%5C%22trading-tasks%5C%22,namespace%3D%5C%22prod%5C%22%7D%20%7C%20json%20%7C%20name%3D~%5C%22trading.task.${name}.*%5C%22%20%7C%20line_format%20%5C%22%7B%7B.levelname%7D%7D.%7B%7B.name%7D%7D%20%7B%7B.message%7D%7D%5C%22%22%7D%5D`;

const link = ({ row }: any) => (
  <Link href={taskUrl(row.name)} target="_blank">
    {row.name}
  </Link>
);

const logLink = ({ row }: { row: Task }) => {
  const color: any = taskColor[row.last_run_state] || "warning";
  return row.last_run_state ? (
    <Chip
      href={logUrl(row.name)}
      target="_blank"
      variant="filled"
      component="a"
      color={color}
      size="small"
      label={row.last_run_state}
    />
  ) : null;
};

const Tasks = ({
  columns,
  ops,
  filters,
}: {
  ops: OpsUser;
  columns?: any[];
  filters?: any;
}) => {
  const defaultCols = React.useMemo(
    () => [
      {
        key: "enabled",
        name: "Enabled",
        width: 80,
        renderCell({ row, onRowChange }: any) {
          return (
            <SelectCellFormatter
              value={row.enabled}
              onChange={async () => {
                const enabled = !row.enabled;
                await ops.taskUpdate(row.name, { enabled });
                onRowChange({ ...row, enabled });
              }}
            />
          );
        },
      },
      {
        key: "name",
        name: "Name",
        minWidth: 120,
        renderCell: link,
      },
      {
        key: "priority",
        name: "Priority",
        maxWidth: 100,
      },
      {
        key: "schedule",
        name: "Schedule",
      },
      {
        key: "last_run_end",
        name: "last run end",
        minWidth: 120,
        renderCell({ row }: { row: Task }) {
          return (
            <Box sx={{ textAlign: "right" }}>
              {formatDateTimeSeconds(new Date(0.001 * row.last_run_end))}
            </Box>
          );
        },
      },
      {
        key: "last_run_duration",
        name: "last run duration (ms)",
        minWidth: 120,
        renderCell({ row }: { row: Task }) {
          return (
            <Box sx={{ textAlign: "right" }}>
              {formatNumber(Math.ceil(0.001 * row.last_run_duration))}
            </Box>
          );
        },
      },
      {
        key: "last_run_state",
        name: "last run state",
        renderCell: logLink,
      },
      {
        key: "description",
        name: "Description",
      },
    ],
    [],
  );

  const cols: any = React.useMemo(() => {
    return columns
      ? defaultCols.filter((col: any) => columns.indexOf(col.key) > -1)
      : defaultCols;
  }, [columns, defaultCols]);

  const api = ops.taskLoader(filters || {});

  return (
    <Page title="Tasks">
      <MonoBox>
        <ApiDataGrid
          api={api}
          columns={cols}
          className="rdg-dark"
          style={style(35)}
        />
      </MonoBox>
    </Page>
  );
};

export default Tasks;
