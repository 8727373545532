import { Account, useStores } from "@fluidily/stores";
import { CrudForm, Crumb } from "@metablock/react";
import React from "react";
import OrgView from "../Views/OrgView";

const AddAccount = ({
  account,
  left,
}: {
  account?: Account;
  left: Crumb[];
}) => {
  const { fluidily, messageStore } = useStores();
  const schemaLoader = fluidily.schemaLoader("AccountCreate");

  const getSchema = async () => {
    return await schemaLoader();
  };

  const submit = async (data: any) => {
    if (Object.keys(data).length > 0) {
      if (account) {
        return await fluidily.accounts.update(account.id, data);
      } else {
        return await fluidily.accounts.create(data);
      }
    }
  };

  const label = account ? "update account" : "create new account";

  return (
    <OrgView title={label} left={left}>
      <CrudForm
        defaults={account}
        schema={getSchema}
        submit={submit}
        label={label}
        onSuccess={(a: Account) => {
          messageStore.success(`Account ${a.id} saved`);
        }}
      />
    </OrgView>
  );
};

export default AddAccount;
