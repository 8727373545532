import BettingEvents from "@fluidily/Views/BettingEvents";
import Page from "@fluidily/Views/Page";
import React from "react";

const SecuritiesHome = () => {
  const filters = {
    tradable: "yes",
  };

  return (
    <Page title={"Betting Events"}>
      <BettingEvents
        filters={filters}
        instrumentUrl="/data/betting_events"
        exchangeUrl="/data/exchanges"
        useUrl={true}
      />
    </Page>
  );
};

export default SecuritiesHome;
