import { NotFound } from "@metablock/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import EventHome from "./Event";
import Events from "./Events";

const TradableBettingRoutes = ({ basePath }: { basePath: string }) => {
  return (
    <Routes>
      <Route path="" element={<Events basePath={basePath} />} />
      <Route path="/:eventId/*" element={<EventHome basePath={basePath} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default TradableBettingRoutes;
