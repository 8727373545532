import { NoSsr } from "@metablock/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Main from "./Main";
import { darkTheme } from "./theme";

const root: HTMLElement = document.getElementById("__metablock") as HTMLElement;

hydrateRoot(
  root,
  <NoSsr>
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Main />
      </ThemeProvider>
    </BrowserRouter>
  </NoSsr>,
);
