import Link from "@mui/material/Link";
import React from "react";

const blockchainMap = new Map<string, (address: string) => string>();

blockchainMap.set(
  "eth",
  (address: string) => `https://etherscan.io/address/${address}`,
);

const BlockchainAddress = ({
  currency,
  address,
}: {
  currency: string;
  address: string;
}) => {
  const urlMaker = blockchainMap.get(currency);
  const url = urlMaker ? urlMaker(address) : "";
  return url ? (
    <Link href={url} target="_blank" rel="noreferrer">
      {address}
    </Link>
  ) : (
    <span>{address}</span>
  );
};

export default BlockchainAddress;
