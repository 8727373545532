import deleteEntry from "@fluidily/Views/deleteEntry";
import { Account, useStores } from "@fluidily/stores";
import { gridFullHeight } from "@fluidily/theme";
import { STRATEGIES } from "@fluidily/utils/options";
import {
  ApiDataGrid,
  Crumb,
  DataGridSelectFilter,
  Link,
  useDataGridFilters,
} from "@metablock/react";
import React from "react";
import OrgView from "../Views/OrgView";

const Accounts = ({ basePath, left }: { basePath: string; left: Crumb[] }) => {
  const { fluidily } = useStores();
  const [ignored, render] = React.useState<any>({});
  const api = fluidily.accounts.loader();
  const dataGridFilters = useDataGridFilters(true);

  const deleteAccount = async (account: Account) => {
    await fluidily.accounts.delete(account.id);
    render({});
  };

  const columns = React.useMemo(
    () => [
      {
        key: "id",
        name: "ID",
        renderCell: ({ row }: { row: Account }) => (
          <Link to={`${basePath}/${row.id}`}>#{row.id}</Link>
        ),
      },
      {
        key: "exchange",
        name: "Exchange",
        renderCell: ({ row }: { row: Account }) => (
          <Link to={`/data/exchanges/${row.exchange}`}>{row.exchange}</Link>
        ),
      },
      {
        key: "strategy",
        name: "Strategy",
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={STRATEGIES}
            {...p}
          />
        ),
      },
      {
        key: "exchange_account_id",
        name: "Exchange ID",
      },
      {
        key: "exchange_account_name",
        name: "Exchange name",
      },
      deleteEntry(
        deleteAccount,
        (row: Account) => `Delete account ${row.id}: ${row.exchange}`,
      ),
    ],
    [dataGridFilters.filters],
  );

  return (
    <OrgView title="accounts" left={left}>
      <ApiDataGrid
        sx={gridFullHeight()}
        title="Trading Accounts"
        columns={columns}
        api={api}
        headerRowHeight={80}
        dataGridFilters={dataGridFilters}
        style={{ flex: 1 }}
        search
      />
    </OrgView>
  );
};

export default Accounts;
