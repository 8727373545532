import { DataApi, urlQuery } from "@metablock/core";
import { LeagueSeason } from "../protos";
import HttpComponent from "./httpComponent";

class Leagues extends HttpComponent {
  loader(query?: any): DataApi<LeagueSeason> {
    return this.cli.loader(`${this.cli.apiUrl}/leagues`, query);
  }

  async getLeague(leagueId: number | string): Promise<LeagueSeason> {
    const response = await this.cli.get(
      `${this.cli.apiUrl}/leagues/${leagueId}`,
    );
    return response.data as LeagueSeason;
  }

  async getExchangeLeagues(params?: any): Promise<any[]> {
    const response = await this.cli.get(
      urlQuery(`${this.cli.apiUrl}/leagues/exchanges`, params),
    );
    return response.data as any[];
  }
}

export default Leagues;
