import { DataApi } from "@metablock/core";
import { Fluidily, Task } from "./client";

class TasksStore {
  fluidily: Fluidily;

  constructor(cli: Fluidily) {
    this.fluidily = cli;
  }

  loader(query?: Record<string, any>): DataApi<Task> {
    return this.fluidily.loader(`${this.fluidily.apiUrl}/tasks`, query);
  }

  async update(name: string, body: Record<string, any>): Promise<Task> {
    const url = `${this.fluidily.apiUrl}/tasks/${name}`;
    const response = await this.fluidily.patch(url, { body });
    return response.data as Task;
  }
}

export { TasksStore };
