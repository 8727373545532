import { MonoBox } from "@fluidily/Components";
import rowDate from "@fluidily/Components/dateFormatter";
import { useCountry } from "@fluidily/hooks";
import {
  BettingEvent,
  bettingEventVolume,
  countryOptions,
  useStores,
} from "@fluidily/stores";
import { gridFullHeight } from "@fluidily/theme";
import { formatDateTime } from "@fluidily/utils/dates";
import { formatNumber } from "@fluidily/utils/money";
import {
  BETTING_EVENT_TYPES,
  BETTING_STATES,
  EXCHANGES,
} from "@fluidily/utils/options";
import {
  ApiDataGrid,
  DataGridNoFilter,
  DataGridSelectFilter,
  Link,
  useDataGridFilters,
} from "@metablock/react";
import Box from "@mui/material/Box";
import React from "react";
import { DataGridMultiSelectFilter } from "./DataGridMultiSelectFilter";
import { ExchangeId } from "./ExchangeSymbol";
import { LeagueUrl } from "./Urls";

const BettingEvents = (props: any) => {
  const {
    filters,
    columns,
    instrumentUrl,
    exchangeUrl,
    useUrl = false,
    ...extra
  } = props;
  const { fluidily } = useStores();
  const dataGridFilters = useDataGridFilters(useUrl, {
    tradable: "yes",
    state: ["NEW", "UPCOMING", "LIVE"],
    ...filters,
  });

  // This is required to force re-render when filters prop change
  React.useEffect(() => {
    dataGridFilters.setSome(filters);
  }, [filters]);

  const countryMap = useCountry() || {};

  const api = fluidily.betting_events.loader();
  const defaultCols = React.useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        minWidth: 130,
        renderHeaderCell: DataGridNoFilter,
        resizable: true,
        renderCell: ({ row }: any) =>
          instrumentUrl ? (
            <Link to={`${instrumentUrl}/${row.id}`}>
              {`${row.id}:${row.security_key.name}`}
            </Link>
          ) : (
            `${row.id}:${row.security_key.name}`
          ),
      },
      {
        key: "league",
        name: "Tournament",
        minWidth: 100,
        renderHeaderCell: DataGridNoFilter,
        resizable: true,
        renderCell: ({ row }: { row: BettingEvent }) => (
          <LeagueUrl league={row.league_season} />
        ),
      },
      {
        key: "country",
        name: "Country",
        resizable: true,
        renderCell: ({ row }: { row: BettingEvent }) => {
          const country =
            countryMap[row.league_season.country.toLocaleLowerCase() || "  "];
          return country
            ? country.name
            : row.league_season.country.toLocaleLowerCase();
        },
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={countryOptions(countryMap)}
            {...p}
          />
        ),
      },
      {
        key: "exchange",
        name: "Exchange",
        resizable: true,
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={EXCHANGES}
            {...p}
          />
        ),
        renderCell: ({ row }: { row: BettingEvent }) =>
          exchangeUrl ? (
            <Link to={`${exchangeUrl}/${row.security_key.exchange}`}>
              {row.security_key.exchange}
            </Link>
          ) : (
            row.security_key.exchange
          ),
      },
      {
        key: "volume",
        name: "Volume",
        maxWidth: 100,
        renderHeaderCell: DataGridNoFilter,
        renderCell: ({ row }: { row: BettingEvent }) => (
          <Box sx={{ textAlign: "right" }}>
            {formatNumber(Math.round(bettingEventVolume(row)))}
          </Box>
        ),
      },
      {
        key: "state",
        name: "State",
        resizable: true,
        minWidth: 100,
        renderHeaderCell: (p: any) => (
          <DataGridMultiSelectFilter
            dataGridFilters={dataGridFilters}
            options={BETTING_STATES}
            {...p}
          />
        ),
      },
      {
        key: "event_type",
        name: "Type",
        resizable: true,
        renderHeaderCell: (p: any) => (
          <DataGridSelectFilter
            dataGridFilters={dataGridFilters}
            options={BETTING_EVENT_TYPES}
            {...p}
          />
        ),
      },
      {
        key: "exchange_id",
        name: "Exchange ID",
        resizable: true,
        renderHeaderCell: DataGridNoFilter,
        renderCell: ({ row }: { row: BettingEvent }) => (
          <ExchangeId betting_event={row} />
        ),
      },
      {
        key: "start__gte",
        name: "Start",
        resizable: true,
        minWidth: 120,
        renderHeaderCell: DataGridNoFilter,
        renderCell: rowDate((row: BettingEvent) => row.start, formatDateTime),
      },
    ],
    [dataGridFilters.filters, countryMap],
  );

  const cols: any = React.useMemo(() => {
    return columns
      ? defaultCols.filter((col: any) => columns.indexOf(col.key) > -1)
      : defaultCols;
  }, [columns, defaultCols]);

  return (
    <MonoBox>
      <ApiDataGrid
        sx={gridFullHeight()}
        api={api}
        columns={cols}
        headerRowHeight={80}
        className="rdg-dark"
        style={{ flex: 1 }}
        dataGridFilters={dataGridFilters}
        search
        {...extra}
      />
    </MonoBox>
  );
};

export default BettingEvents;
