export interface Country {
  name: string;
  official_name: string;
  alpha_2: string;
  alpha_3: string;
  numeric: string;
}

export const countryOptions = (countryMap: {
  [key: string]: Country;
}): any[] => {
  const countries = Object.keys(countryMap).map((k) => {
    const country = countryMap[k];
    return {
      value: k,
      label: country ? country.name : k,
    };
  });
  countries.unshift({ value: "", label: "all" });
  return countries;
};
