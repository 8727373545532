import { useStores } from "@fluidily/stores";
import { NotFound } from "@metablock/react";
import React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import AccountsRoutes from "./Accounts";
import TradableBettingRoutes from "./Betting";
import DashboardsRoutes from "./Dashboards";
import MarketsRoutes from "./Markets";

const OrgIndex = () => {
  const { fluidily, accountStore } = useStores();
  const { orgName } = useParams() as { orgName: string };
  const basePath = `orgs/${orgName}`;
  const orgMember = accountStore.orgsByName[orgName];
  fluidily.orgId = orgMember.org_id;
  React.useEffect(() => {
    if (orgMember) {
      accountStore.setOrg(orgMember);
    }
    return () => {
      accountStore.setOrg();
    };
  });
  if (!orgMember) {
    return <NotFound />;
  }

  return (
    <Routes>
      <Route path="" element={<Navigate to={`/${basePath}/dashboards`} />} />
      <Route
        path="/accounts/*"
        element={<AccountsRoutes basePath={`/${basePath}/accounts`} />}
      />
      <Route
        path="/markets/*"
        element={<MarketsRoutes basePath={`/${basePath}/markets`} />}
      />
      <Route
        path="/betting/*"
        element={<TradableBettingRoutes basePath={`/${basePath}/betting`} />}
      />
      <Route
        path="/dashboards/*"
        element={<DashboardsRoutes basePath={`/${basePath}/dashboards`} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default OrgIndex;
