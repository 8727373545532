import { Notebook } from "@metablock/notebook";
import { timer } from "d3-timer";
import Resize from "./resize";

class Particles extends HTMLElement {
  timer: any;
  width: any;
  color: any;
  height: any;
  params: any;
  resize: Resize | undefined;
  context: CanvasRenderingContext2D | undefined;

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  getParams() {
    const aspectRatio = +(this.getAttribute("aspect-ratio") || 70);
    const width_ = +(this.getAttribute("width") || 0);
    const height_ = +(this.getAttribute("height") || 0);
    const radius = +(this.getAttribute("radius") || 2.5);
    const color = this.getAttribute("color");
    const minDistance = +(this.getAttribute("min-distance") || 40);
    const maxDistance = +(this.getAttribute("max-distance") || 60);
    const width =
      width_ || this.clientWidth || this.parentElement?.clientWidth || 600;
    const height = height_ || (width * aspectRatio) / 100;
    return {
      width,
      height,
      particles: this.updateParticles(width, height),
      radius,
      minDistance,
      maxDistance,
      color,
      minDistance2: minDistance * minDistance,
      maxDistance2: maxDistance * maxDistance,
    };
  }

  updateParticles(width: number, height: number): Array<number> {
    const n = this.numParticles(width, height);
    const particles = new Array(n);
    for (let i = 0; i < n; ++i) {
      particles[i] = {
        x: Math.random() * width,
        y: Math.random() * height,
        vx: 0,
        vy: 0,
      };
    }
    return particles;
  }

  numParticles(width: number, height: number): number {
    const n = +(this.getAttribute("num-particles") || 0);
    if (n > 0) {
      return n;
    }
    const d = +(this.getAttribute("particles-density") || 5);
    const area = width * height;
    return d * Math.floor(area / 10000);
  }

  newContext(): CanvasRenderingContext2D {
    const notebook = Notebook.installed();
    const context = notebook.context2d(this.params.width, this.params.height);
    const color_ =
      this.params.color || (notebook.options.mode === "dark" ? "#fff" : "#000");
    context.strokeStyle = color_;
    context.fillStyle = color_;
    this.shadowRoot?.replaceChildren(context.canvas);
    return context;
  }

  refresh() {
    this.params = this.getParams();
    this.context = this.newContext();
  }

  connectedCallback() {
    this.resize = new Resize(() => this.refresh());

    this.refresh();

    this.timer = timer(() => {
      const context = this.context;
      const pa = this.params;
      if (!context) return;
      context.save();
      context.clearRect(0, 0, pa.width, pa.height);

      for (let i = 0; i < pa.particles.length; ++i) {
        const p = pa.particles[i];
        p.x += p.vx;
        if (p.x < -pa.maxDistance) p.x += pa.width + pa.maxDistance * 2;
        else if (p.x > pa.width + pa.maxDistance)
          p.x -= pa.width + pa.maxDistance * 2;
        p.y += p.vy;
        if (p.y < -pa.maxDistance) p.y += pa.height + pa.maxDistance * 2;
        else if (p.y > pa.height + pa.maxDistance)
          p.y -= pa.height + pa.maxDistance * 2;
        p.vx += 0.2 * (Math.random() - 0.5) - 0.01 * p.vx;
        p.vy += 0.2 * (Math.random() - 0.5) - 0.01 * p.vy;
        context.beginPath();
        context.arc(p.x, p.y, pa.radius, 0, 2 * Math.PI);
        context.fill();
      }

      for (let i = 0; i < pa.particles.length; ++i) {
        for (let j = i + 1; j < pa.particles.length; ++j) {
          const pi = pa.particles[i];
          const pj = pa.particles[j];
          const dx = pi.x - pj.x;
          const dy = pi.y - pj.y;
          const d2 = dx * dx + dy * dy;
          if (d2 < pa.maxDistance2) {
            context.globalAlpha =
              d2 > pa.minDistance2
                ? (pa.maxDistance2 - d2) / (pa.maxDistance2 - pa.minDistance2)
                : 1;
            context.beginPath();
            context.moveTo(pi.x, pi.y);
            context.lineTo(pj.x, pj.y);
            context.stroke();
          }
        }
      }
      context.restore();
    });
  }

  disconnectedCallback() {
    if (this.resize) this.resize.destroy();
    if (this.timer) this.timer.stop();
  }
}

if (customElements.get("connected-particles") === undefined) {
  customElements.define("connected-particles", Particles);
}

export default Particles;
